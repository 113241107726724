import React from 'react';
import {Checkbox, Collapse, Card} from "@blueprintjs/core";
import {fieldValidation, renderErrorComponent} from "../../core/Helpers/helper";

export default class GSTDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.defaultProps();
        this.handleChange = this.handleChange.bind(this);

    }


    defaultProps = () => {
        return {
            gstDetails: this.props.gstDetails,
            skeleton: this.props.skeleton,
            errors: this.props.errors,
        };
    }

    componentWillReceiveProps(nextProps) {

        this.setState({
            gstDetails: nextProps.gstDetails,
            skeleton: nextProps.skeleton,
            errors: nextProps.errors,
        });
    }

    handleChange() {
        var gstDetails = this.state.gstDetails;

        Object.assign(gstDetails, {isGstDetailsFilled: !gstDetails.isGstDetailsFilled});

        if (gstDetails.isGstDetailsFilled === false) {
            let errors = this.state.errors;

            Object.assign(errors, {gstDetails: {}});

            this.setState({
                errors
            })
        }

        this.setState({
            gstDetails
        })
    }

    updateGstDetails = (e, fieldName, validationRule = []) => {

        let value = (e.target === undefined) ? e.value : e.target.value;

        if (validationRule.length > 0) {

            let validateField = fieldValidation(value, fieldName, validationRule);

            let errors = this.state.errors;

            Object.assign(errors['gstDetails'], {[fieldName]: validateField.errors});

            this.setState({
                errors
            });

            /*if (validateField.status === false) {
                return false;
            }*/
        }

        var gstDetails = this.state.gstDetails;

        Object.assign(gstDetails, {[fieldName]: value});

    }


    render() {
        return (
            <React.Fragment>
                <Card className="rounded-0">
                    <div className={"row"}>
                        <div className={"col"}>
                            <h1 className={"text-dark m-0"}>
                                <Checkbox
                                    className="m-0"
                                    checked={this.state.gstDetails.isGstDetailsFilled}
                                    onChange={this.handleChange}>
                                    &nbsp; GST Details (Optional)
                                </Checkbox>
                            </h1>
                        </div>
                    </div>
                </Card>

                <Collapse isOpen={this.state.gstDetails.isGstDetailsFilled}>
                    <Card className="rounded-0">
                        <div className="col-12 px-0">
                            <div className="row">
                                <div className="col-sm-6 col-12">
                                    <input
                                        type="text"
                                        className="bp3-input"
                                        required
                                        onChange={(e, fieldName) => this.updateGstDetails(e, "gst_no", ['required', "regx,^\\d{2}[A-Z]{5}\\d{4}[A-Z]{1}[A-Z\\d]{1}[Z]{1}[A-Z\\d]{1}$,GST Number format is Invalid."])}

                                    />
                                    <label className="form-control-placeholder">GST No.</label>
                                    {(this.state.errors.gstDetails !== undefined) ? (this.state.errors.gstDetails['gst_no'] !== undefined) ? renderErrorComponent(this.state.errors.gstDetails['gst_no']) : "" : ""}

                                </div>
                                <div className="col-sm-6 col-12 mt-s-4">
                                    <input
                                        type="text"
                                        className="bp3-input"
                                        required
                                        onChange={(e, fieldName) => this.updateGstDetails(e, "name", ['required'])}

                                    />
                                    <label className="form-control-placeholder">Company Name</label>
                                    {(this.state.errors.gstDetails !== undefined) ? (this.state.errors.gstDetails['name'] !== undefined) ? renderErrorComponent(this.state.errors.gstDetails['name']) : "" : ""}

                                </div>
                                <div className="col-sm-6 col-12 mt-4">
                                    <input
                                        type="text"
                                        className="bp3-input"
                                        required
                                        onChange={(e, fieldName) => this.updateGstDetails(e, "email", ['required', 'email'])}
                                    />
                                    <label className="form-control-placeholder">Email Address</label>
                                    {(this.state.errors.gstDetails !== undefined) ? (this.state.errors.gstDetails['email'] !== undefined) ? renderErrorComponent(this.state.errors.gstDetails['email']) : "" : ""}

                                </div>
                                <div className="col-sm-6 col-12 mt-4">
                                    <input
                                        type="text"
                                        className="bp3-input"
                                        required
                                        onChange={(e, fieldName) => this.updateGstDetails(e, "contact_no", ['required', 'numeric', 'length,10'])}

                                    />
                                    <label className="form-control-placeholder">Phone No.</label>
                                    {(this.state.errors.gstDetails !== undefined) ? (this.state.errors.gstDetails['contact_no'] !== undefined) ? renderErrorComponent(this.state.errors.gstDetails['contact_no']) : "" : ""}

                                </div>
                                <div className="col-sm-6 col-12 mt-4">
                                    <input
                                        type="text"
                                        className="bp3-input"
                                        required
                                        onChange={(e, fieldName) => this.updateGstDetails(e, "address", ['required'])}

                                    />
                                    <label className="form-control-placeholder">Address</label>
                                    {(this.state.errors.gstDetails !== undefined) ? (this.state.errors.gstDetails['address'] !== undefined) ? renderErrorComponent(this.state.errors.gstDetails['address']) : "" : ""}

                                </div>
                                <div className="col-sm-6 col-12 mt-4">
                                    <input
                                        type="text"
                                        className="bp3-input"
                                        required
                                        onChange={(e, fieldName) => this.updateGstDetails(e, "state", ['required'])}

                                    />
                                    <label className="form-control-placeholder">State</label>
                                    {(this.state.errors.gstDetails !== undefined) ? (this.state.errors.gstDetails['state'] !== undefined) ? renderErrorComponent(this.state.errors.gstDetails['state']) : "" : ""}

                                </div>
                                <div className="col-sm-6 col-12 mt-4">
                                    <input
                                        type="text"
                                        className="bp3-input"
                                        required
                                        onChange={(e, fieldName) => this.updateGstDetails(e, "city", ['required'])}

                                    />
                                    <label className="form-control-placeholder">City</label>
                                    {(this.state.errors.gstDetails !== undefined) ? (this.state.errors.gstDetails['city'] !== undefined) ? renderErrorComponent(this.state.errors.gstDetails['city']) : "" : ""}

                                </div>
                                <div className="col-sm-6 col-12 mt-4">
                                    <input
                                        type="text"
                                        className="bp3-input"
                                        required
                                        onChange={(e, fieldName) => this.updateGstDetails(e, "pincode", ['required'])}

                                    />
                                    <label className="form-control-placeholder">Pincode</label>
                                    {(this.state.errors.gstDetails !== undefined) ? (this.state.errors.gstDetails['pincode'] !== undefined) ? renderErrorComponent(this.state.errors.gstDetails['pincode']) : "" : ""}

                                </div>
                            </div>
                        </div>
                    </Card>
                </Collapse>
            </React.Fragment>
        )
    }
}