import React from 'react';
import {Card, Elevation} from "@blueprintjs/core";
import SingleRoomDetail from './SingleRoomDetail';

export default class ChooseRoom extends React.Component {

    constructor(props) {
        super(props);

        this.state = this.defaultProps();
    }

    defaultProps = () => {
        return {
            skeleton: this.props.skeleton,
            roomsDetails: this.props.roomsDetails,
            provider: this.props.provider,
            selectedRoom: this.props.selectedRoom,
        };
    }


    componentWillReceiveProps(nextProps) {

        this.setState({
            skeleton: nextProps.skeleton,
            roomsDetails: nextProps.roomsDetails,
            provider: nextProps.provider,
            selectedRoom: nextProps.selectedRoom,

        })
    }

    bookRoom = (selectedRoom) => {
        this.props.bookRoom(selectedRoom);
    }

    render() {
        return (
            <React.Fragment>
                <Card interactive={false} elevation={Elevation.TWO} className="col-12">
                    <div className="card-header">
                        <h2>Choose Room</h2>
                    </div>
                    <div className="card-body">
                        <br/>
                        {(this.state.roomsDetails.length > 0 && this.state.skeleton === false) ?
                            this.state.roomsDetails.map((singleRoomsDetail, key) => {
                                return <SingleRoomDetail key={key} roomsDetails={singleRoomsDetail}
                                                         provider={this.state.provider}
                                                         selectedRoom={this.state.selectedRoom}
                                                         bookRoom={(selectedRoom) => this.bookRoom(selectedRoom)}
                                                         skeleton={this.state.skeleton}/>;
                            })
                            :
                            <SingleRoomDetail roomsDetails={null} skeleton={this.state.skeleton}/>
                        }

                    </div>
                </Card>
            </React.Fragment>
        )
    }
}