import React from 'react';
import {Button, Intent} from "@blueprintjs/core";

export default class RecentlySearched extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            recentViewed: this.recentViewed().reverse()
        };
    }

    recentViewed = () => {


        let recentViewed = localStorage.getItem('recentViewed');

        if (recentViewed === null) {

            recentViewed = [];

        } else {
            recentViewed = JSON.parse(recentViewed);

            if (!Array.isArray(recentViewed)) {

                recentViewed = [];
            }
        }

        return recentViewed;
    }

    searchHotel = (searchCriteria) => {
        const formData = new FormData();

        formData.append('locationLabel', (searchCriteria.locationLabel.length > 20) ? searchCriteria.locationLabel.substring(0, 20) + "..." : searchCriteria.locationLabel);
        formData.append('startDate', new Date(searchCriteria.startDate).getTime());
        formData.append('endDate', new Date(searchCriteria.endDate).getTime());
        formData.append('rooms', searchCriteria.rooms);
        formData.append('location', searchCriteria.location);

        const queryString = new URLSearchParams(formData).toString();
        window.location = process.env.REACT_APP_URL + 'search?' + queryString;

    }

    render() {

        let dateFormatOptions = {month: 'short', day: 'numeric'};

        return (

            <div className="rec_search mt-3 mt-s-5">
                <div className="row">
                    <div className="col-12 text-left">
                        <h4 className="m-0">Recent Search</h4>
                        {this.state.recentViewed.map((place) => {
                            return <React.Fragment>

                                <Button className="bp3-button bp3-small btn-rec-src mt-3"
                                        onClick={(searchCriteria) => this.searchHotel(place)}
                                        intent={Intent.MINIMAL}>
                                    {(place.locationLabel.length > 20) ? place.locationLabel.substring(0, 20) + "..." : place.locationLabel}
                                    <span className="dot"></span>
                                    {new Date(place.startDate).toLocaleDateString("en-IN", dateFormatOptions)} -
                                    {new Date(place.endDate).toLocaleDateString("en-IN", dateFormatOptions)} | {place.totalGuests} Guest
                                </Button>
                                &nbsp; &nbsp;
                            </React.Fragment>
                        })}

                    </div>
                </div>
            </div>

        );
    }
}