import React from 'react';
import {Card, Elevation} from "@blueprintjs/core";
import renderHTML from 'react-render-html';
import {convertToHtmlChar} from '../../core/Helpers/helper';

export default class Overview extends React.Component {

    constructor(props) {
        super(props);

        this.state = this.defaultProps();
    }

    defaultProps = () => {
        return {
            skeleton: this.props.skeleton,
            description: this.props.description,
        };
    }


    componentWillReceiveProps(nextProps) {

        this.setState({
            skeleton: nextProps.skeleton,
            description: nextProps.description,

        })
    }

    render() {
        return (
            <React.Fragment>
                <Card interactive={false} elevation={Elevation.TWO} className="col-12">
                    <div className="card-header">
                        <h2>Overview</h2>
                    </div>
                    <div className="card-body">
                        <br/>
                        {
                            (this.state.skeleton === true) ?
                                <React.Fragment>
                                    <h4 className={"bp3-skeleton"}>Enjoy a luxurious experience in a
                                        beautiful
                                        setting!</h4>
                                    <span className={"bp3-skeleton"}>Welcome to a tropical paradise called Goa where
                                        Toshali Goan Village
                                        Beach Resort welcomes Beach Resort welcomes Beach Resort welcomes

                                    </span><br/>
                                    <span className={"bp3-skeleton"}>Welcome to a tropical paradise called Goa where
                                        Toshali Goan Village
                                        Beach Resort welcomes Beach Resort welcomes Beach Resort welcomes

                                    </span><br/>
                                    <span className={"bp3-skeleton"}>Welcome to a tropical paradise called Goa where
                                        Toshali Goan Village
                                        Beach Resort welcomes Beach Resort welcomes Beach Resort welcomes

                                    </span><br/>
                                    <span className={"bp3-skeleton"}>Welcome to a tropical paradise called Goa where
                                        Toshali Goan Village
                                        Beach Resort welcomes Beach Resort welcomes Beach Resort welcomes

                                    </span><br/>
                                    <span className={"bp3-skeleton"}>Welcome to a tropical paradise called Goa where
                                        Toshali Goan Village
                                        Beach Resort welcomes Beach Resort welcomes Beach Resort welcomes

                                    </span><br/>
                                    <span className={"bp3-skeleton"}>Welcome to a tropical paradise called Goa where
                                        Toshali Goan Village
                                        Beach Resort welcomes Beach Resort welcomes Beach Resort welcomes

                                    </span><br/>
                                    <span className={"bp3-skeleton"}>Welcome to a tropical paradise called Goa where
                                        Toshali Goan Village
                                        Beach Resort welcomes Beach Resort welcomes Beach Resort welcomes

                                    </span><br/>
                                    <span className={"bp3-skeleton"}>Welcome to a tropical paradise called Goa where
                                        Toshali Goan Village
                                        Beach Resort welcomes Beach Resort welcomes Beach Resort welcomes

                                    </span><br/>

                                </React.Fragment>
                                :
                                <React.Fragment>
                                    {renderHTML(convertToHtmlChar(this.state.description))}
                                </React.Fragment>
                        }
                    </div>
                </Card>
            </React.Fragment>
        )
    }
}