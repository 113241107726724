import React from 'react';
import Loading from './loading';
import Config from "../../Config";
import {ssoInstance} from "gg-react-utilities";
import SingleCity from './SingleCity';

export default class PopularCityList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            skeleton: true,
            isApiException: false,
            cityList: []
        }


    }

    componentDidMount() {

        this.getPopularCityList();

    }

     setSearchByPopularCityData = (data) => {
         this.props.setSearchByPopularCityData(data);
     }

    getPopularCityList = () => {


        const ROOT_URL = Config.API_URL;
        const GET_POPULAR_CITY_LIST_API = `api/popular-city-list`;
        const METHOD = 'get';
        ssoInstance({
            baseURL: `${ROOT_URL}`,
            url: `${GET_POPULAR_CITY_LIST_API}`,
            method: METHOD,
        }).then(response => {
            // do you have to `parse`? I don't remember...
            if (response && response.data && response.data.data) {

                this.setState({
                    skeleton: false,
                    cityList: response.data.data
                });

            }
        }).catch(error => {
            // whoops! what now?
            this.setState({
                isApiException: true,
                skeleton: false,
            });

        });
    };


    render() {
        return (
            <React.Fragment>
                <div className="col-12">
                    <div className="row">
                        <h2 className="destination-name">Top Hotel Destinations</h2>
                    </div>
                </div>
                <div className="row mt-3 px-s-2">
                    {
                        this.state.skeleton ? <Loading/> :
                            <React.Fragment>
                                {
                                    this.state.cityList.map((city) => {

                                        return <SingleCity cityData={city}
                                                           setSearchByPopularCityData={(data) => this.setSearchByPopularCityData(data)}/>;
                                    })
                                }
                            </React.Fragment>
                    }
                </div>
            </React.Fragment>
        )
    }
}