import React from 'react';
import PrivateRoute from './PrivateRoute';
import {Switch} from 'react-router-dom';
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "gg-react-utilities/src/css/style.css";
import "gg-react-utilities/src/css/gg-styles.css";
import {Notfound, Maintance} from "gg-react-utilities";
import Config from '../Config';
import Home from '../Home/index';
import HotelListContainer from '../SearchResultList/containers/HotelListContainer';
import HotelDetails from '../HotelDetails';
import Booking from '../Booking'
import BookingDetail from '../BookingDetail'
import BookingFailed from "../BookingFailed";

export default class Root extends React.Component {
    render() {
        return (
            <React.Fragment>
                {Config.IS_MAINTENANCE_MODE ?
                    <Switch>
                        <PrivateRoute path="*" component={Maintance}/>
                    </Switch>
                    :
                    <Switch>
                        <PrivateRoute exact path="/" component={Home}/>
                        <PrivateRoute exact path="/search" component={HotelListContainer}/>
                        <PrivateRoute exact path="/hotel" component={HotelDetails}/>
                        <PrivateRoute exact path="/booking" component={Booking}/>
                        <PrivateRoute exact path="/booking/:bookingId/detail" component={BookingDetail}/>
                        <PrivateRoute exact path="/booking/failed" component={BookingFailed}/>
                        <PrivateRoute path="*" component={Notfound}/>
                    </Switch>
                }
            </React.Fragment>
        );
    }
}