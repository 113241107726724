import React from 'react';


export default class HotelReviewsPanel extends React.Component {
    constructor(props) {
        super(props);

        this.state = this.defaultProps();
    }

    defaultProps = () => {
        return {
            skeleton: this.props.skeleton,
            reviewsList: this.props.reviews,
        };
    }


    componentWillReceiveProps(nextProps) {

        this.setState({
            skeleton: nextProps.skeleton,
            reviewsList: nextProps.reviews,

        })
    }

    render() {
        let avgRatings = {
            roomQuality: 0,
            serviceQuality: 0,
            diningQuality: 0,
            cleanliness: 0,
        };

        this.state.reviewsList.map((review) => {
            Object.assign(avgRatings, {
                roomQuality: !isNaN(parseFloat(avgRatings.roomQuality) + parseFloat(review.RoomQuality)) ? parseFloat(avgRatings.roomQuality) + parseFloat(review.RoomQuality) : 0,
                serviceQuality: !isNaN(parseFloat(avgRatings.serviceQuality) + parseFloat(review.ServiceQuality)) ? parseFloat(avgRatings.serviceQuality) + parseFloat(review.ServiceQuality) : 0,
                diningQuality: !isNaN(parseFloat(avgRatings.diningQuality) + parseFloat(review.DiningQuality)) ? parseFloat(avgRatings.diningQuality) + parseFloat(review.DiningQuality) : 0,
                cleanliness: !isNaN(parseFloat(avgRatings.cleanliness) + parseFloat(review.Cleanliness)) ? parseFloat(avgRatings.cleanliness) + parseFloat(review.Cleanliness) : 0,
            });
            return true;
        });
        Object.assign(avgRatings, {
            roomQuality: !isNaN(parseFloat(avgRatings.roomQuality) / this.state.reviewsList.length) ? parseFloat(avgRatings.roomQuality) / this.state.reviewsList.length : 0,
            serviceQuality: !isNaN(parseFloat(avgRatings.serviceQuality) / this.state.reviewsList.length) ? parseFloat(avgRatings.serviceQuality) / this.state.reviewsList.length : 0,
            diningQuality: !isNaN(parseFloat(avgRatings.diningQuality) / this.state.reviewsList.length) ? parseFloat(avgRatings.diningQuality) / this.state.reviewsList.length : 0,
            cleanliness: !isNaN(parseFloat(avgRatings.cleanliness) / this.state.reviewsList.length) ? parseFloat(avgRatings.cleanliness) / this.state.reviewsList.length : 0,
        });

        return (
            <div className="">

                <table className="bp3-html-table">
                    <thead>
                    <tr>
                        <th>Average Guest Rating</th>
                        <th></th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td style={{width: '45%'}}>
                            <h6>
                                Room Quality
                                <div className="avg_rate">
                                    <div>{parseFloat(avgRatings.roomQuality).toFixed(1)}</div>
                                    <div
                                        className="bp3-progress-bar bp3-intent-success bp3-no-stripes bp3-no-animation ml-3">
                                        <div className="bp3-progress-meter"
                                             style={{width: avgRatings.roomQuality * 20 + '%'}}></div>
                                    </div>
                                </div>
                            </h6>
                            <h6>
                                Service Quality
                                <div className="avg_rate">
                                    <div>{parseFloat(avgRatings.serviceQuality).toFixed(1)}</div>
                                    <div
                                        className="bp3-progress-bar bp3-intent-success bp3-no-stripes bp3-no-animation ml-3">
                                        <div className="bp3-progress-meter"
                                             style={{width: avgRatings.serviceQuality * 20 + '%'}}></div>
                                    </div>
                                </div>
                            </h6>
                            <h6>
                                Dining Quality
                                <div className="avg_rate">
                                    <div>{parseFloat(avgRatings.diningQuality).toFixed(1)}</div>
                                    <div
                                        className="bp3-progress-bar bp3-intent-success bp3-no-stripes bp3-no-animation ml-3">
                                        <div className="bp3-progress-meter"
                                             style={{width: avgRatings.diningQuality * 20 + '%'}}></div>
                                    </div>
                                </div>
                            </h6>
                            <h6>
                                Cleanliness
                                <div className="avg_rate">
                                    <div>{parseFloat(avgRatings.cleanliness).toFixed(1)}</div>
                                    <div
                                        className="bp3-progress-bar bp3-intent-success bp3-no-stripes bp3-no-animation ml-3">
                                        <div className="bp3-progress-meter"
                                             style={{width: avgRatings.cleanliness * 20 + '%'}}></div>
                                    </div>
                                </div>
                            </h6>
                        </td>
                        <td></td>
                        <td></td>
                    </tr>
                    </tbody>
                </table>
                <hr/>
                {
                    this.state.reviewsList.map((review, key) => {

                        return <React.Fragment key={key}>
                            <div className="comments">

                                <div className="d-lg-flex align-items-sm-center justify-content-sm-between">
                                    <span><b>{review.Customer_name.toLowerCase()}</b> from {review.Consumer_city.toLowerCase()}, {review.Consumer_Country.toLowerCase()} recommends
                                        this hotel (<span
                                            className="font-12 text-primary">{(review.Post_Date !== null) ? "posted on : " + new Date(review.Post_Date).toLocaleDateString() : ""}</span>
)
                                    </span>
                                    <ul className="d-sm-flex align-items-sm-center">
                                        <li>
                                            Room Quality : {review.RoomQuality}/5
                                        </li>
                                        <li>
                                            Service Quality : {review.ServiceQuality}/5
                                        </li>
                                        <li>
                                            Dining Quality : {review.DiningQuality}/5
                                        </li>
                                        <li>
                                            Cleanliness : {review.Cleanliness}/5
                                        </li>
                                    </ul>
                                </div>
                                <p>{review.Comments}</p>
                            </div>
                            <hr/>
                        </React.Fragment>

                    })
                }

                <div className="bg-alert col-12" role="alert">
                    <div className="d-flex align-items-center justify-content-sm-end">
                        <span className="font-10 text-primary">total {this.state.reviewsList.length} Review</span>
                    </div>
                </div>
            </div>
        )
    }
}