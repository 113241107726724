import {base64_url_decode} from "../components/QueryString";

export const validateAndReFormatData = (values) => {

    let bookingDates = validateBookingDate(values);
    let rooms = validateBookingRooms(values);
    let location = validateLocation(values);

    let data = Object.assign(bookingDates, rooms, location);

    if (values.hotel !== undefined && values.provider !== undefined) {
        Object.assign(data, {
            hotel: base64_url_decode(values.hotel),
            provider: values.provider,
        });
    }
    if (values.selectedRoom !== undefined) {
        Object.assign(data, {
            selectedRoom: base64_url_decode(values.selectedRoom),
        });
    }

    return data;
};
const validateBookingDate = (values) => {

    let newStartDate = new Date();
    let newEndDate = new Date(new Date(new Date().getFullYear(), (new Date().getMonth()), (new Date().getDate() + 1)));

    try {
        newStartDate = (values.startDate === undefined) ? this.state.startDate : new Date(parseFloat(values.startDate));
        newEndDate = (values.endDate === undefined) ? this.state.endDate : new Date(parseFloat(values.endDate));
    }
    catch (err) {

        newStartDate = this.state.startDate;
        newEndDate = this.state.endDate;
    }

    if (newStartDate <= (new Date())) {
        newStartDate = new Date();
    }
    if (newEndDate <= newStartDate) {
        newEndDate = new Date(new Date(new Date().getFullYear(), (new Date().getMonth()), (new Date().getDate() + 1)));
    }


    let diffTime = Math.abs(newEndDate.getTime() - newStartDate.getTime());
    let totalNights = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return {
        startDate: newStartDate,
        endDate: newEndDate,
        totalNights: totalNights,
    };


}

const validateBookingRooms = (values) => {
    let rooms = [
        {
            adults: 2,
            children: 0
        }
    ];
    let guestCount = {
        adults: 0,
        children: 0,
        total: 0,
    };

    try {
        rooms = JSON.parse(base64_url_decode(values.rooms));
    } catch (e) {

    }

    let newRoomList = [];
    rooms.map((room, i) => {

        if (room.adults !== undefined && room.children !== undefined && !isNaN(room.adults) && !isNaN(room.children)) {
            if (i === 0) {
                if (room.adults <= 0 && room.children <= 0) {
                    newRoomList.push({
                        adults: 2,
                        children: 0,
                        child_age: [],
                        guest_details: [],

                    });
                    Object.assign(guestCount, {
                        adults: 2,
                        children: 0,
                        total: 0,
                    });
                } else {
                    let childAges = [];

                    for (let childCount = 0; childCount < room.children; childCount++) {
                        childAges.push(process.env.REACT_APP_HOTEL_MAX_CHILD_AGE);
                    }

                    newRoomList.push({
                        adults: room.adults,
                        children: room.children,
                        child_age: childAges,
                        guest_details: [],


                    });

                    Object.assign(guestCount, {
                        adults: parseInt(guestCount.adults) + parseInt(room.adults),
                        children: parseInt(guestCount.children) + parseInt(room.children),
                        total: parseInt(guestCount.total) + parseInt(room.adults) + parseInt(room.children),
                    });
                }
            } else {
                if (room.adults >= 0 && room.children >= 0) {
                    let childAges = [];

                    for (let childCount = 0; childCount < room.children; childCount++) {
                        childAges.push(process.env.REACT_APP_HOTEL_MAX_CHILD_AGE);
                    }

                    newRoomList.push({
                        adults: room.adults,
                        children: room.children,
                        child_age: childAges,
                        guest_details: [],

                    });

                    Object.assign(guestCount, {
                        adults: parseInt(guestCount.adults) + parseInt(room.adults),
                        children: parseInt(guestCount.children) + parseInt(room.children),
                        total: parseInt(guestCount.total) + parseInt(room.adults) + parseInt(room.children),
                    });
                }
            }
        }

        return true;
    });

    return {rooms: newRoomList, guestCount: guestCount};

}

const validateLocation = (values) => {

    return {
        location: values.location,
        locationLabel: decodeURI(values.locationLabel),
    };

}