import {GET_HOTEL_LIST} from "../../SearchResultList/actions";

let initialState = {
    hotelList: []
};

const hotelListResults = (state = initialState, action) => {

    switch (action.type) {

        case GET_HOTEL_LIST:
            return {...state, hotelList: action.sources}
        default:
            return state
    }
}

export default hotelListResults;
