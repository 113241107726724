import React from 'react';
import TopBar from "../core/components/TopBar";
import {Card} from "@blueprintjs/core";
import {base64_url_decode, getAllUrlParams} from "../core/components/QueryString";

export default class BookingFailed extends React.Component {

    constructor(props) {
        super(props);

        this.state = this.defaultProps();
    }

    defaultProps = () => {
        return {
            failureMessage: "",
        };
    }

    componentDidMount() {
        let values = getAllUrlParams(decodeURI(window.location.search));
        let failureMessage = "";

        if (values.message !== undefined) {

            failureMessage = base64_url_decode(values.message);
        }

        this.setState({failureMessage});
    }

    render() {
        return (
            <div className="container">
                <TopBar
                    title={"Booking Operation Failed"} showBackIcon={false}/>
                <section className="Booking-Details pb-5">
                    <Card>
                        <div className="container">
                            <div className="row">
                                <div className="col-12 px-0">
                                    <div className="text-center text-muted">
                                        <div>
                                            <center>
                                                <img
                                                    src="https://s3.ap-south-1.amazonaws.com/gg-statics/empty-states/svg/payment_failed.svg"
                                                    alt="notFound" className="notFound img-fluid"/><h3
                                                className="mt-lg-4 mt-md-4 mt-sm-3 mt-xl-4 mx-lg-5 px-lg-5">
                                                <b>{this.state.failureMessage.split("|#|").map((message) => {
                                                    return <React.Fragment>{message}<br/></React.Fragment>
                                                })}</b></h3><a
                                                className="bp3-button bp3-intent-primary mt-2"
                                                href={process.env.REACT_APP_URL}>Go Home</a>
                                            </center>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                </section>
            </div>
        );

    }
}