import React from 'react';
import {Button, Intent, Collapse, Spinner} from "@blueprintjs/core";
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import Amenity from './Amenity';
import Category from "./Category";
import HotelGroup from "./HotelGroup";

export default class Filters extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            skeleton: this.props.skeleton,
            isPriceOpen: false,
            isAmenitiesOpen: false,
            isCategoriesOpen: false,
            isHotelGroupOpen: false,
            price: this.props.filters.price,
            amenities: [],
            categories: [],
            hotelGroup: [],
            filters: this.props.filters,
            setFiltersForFirstTime: true,
            keepChildrenMounted: true,
            isPackageOpen: false,
            isFilterDrawer: false,
        };
    }


    componentWillReceiveProps(nextProps) {


        let filterOptions = nextProps.filterOptions;


        if (filterOptions.price !== undefined) {

            let filters = nextProps.filters;

            if (this.state.setFiltersForFirstTime === true) {
                Object.assign(filters, {price: filterOptions.price});
            }

            this.setState({
                skeleton: nextProps.skeleton,
                price: filterOptions.price,
                isPriceOpen: (filterOptions.isPriceOpen !== undefined) ? filterOptions.isPriceOpen : true,
                amenities: filterOptions.amenities,
                categories: filterOptions.category,
                hotelGroup: filterOptions.hotel_group,
                filters,
                setFiltersForFirstTime: false
            })
        }


    }

    ChangeSlider = (e) => {
        let filters = this.state.filters;
        Object.assign(filters, {price: {min: e.min, max: e.max}});
        this.setState({
            filters
        })
    }

    updateAmenities = (amenityList) => {
        let filters = this.state.filters;
        Object.assign(filters, {amenities: amenityList});

        this.setState({
            filters
        }, () => {
            this.props.updateFilters(this.state.filters);
        })

    }
    updateCategories = (categoryList) => {

        let filters = this.state.filters;
        Object.assign(filters, {categories: categoryList});

        this.setState({
            filters
        }, () => {
            this.props.updateFilters(this.state.filters);
        })

    }

    updateHotelGroups = (hotelGroupList) => {

        let filters = this.state.filters;
        Object.assign(filters, {hotel_group: hotelGroupList});

        this.setState({
            filters
        }, () => {
            this.props.updateFilters(this.state.filters);
        })

    }

    PriceClick = () => {
        this.setState({isPriceOpen: !this.state.isPriceOpen,})
    }

    AmenitiesClick = () => {
        this.setState({isAmenitiesOpen: !this.state.isAmenitiesOpen,})
    }
    CategoriesClick = () => {
        this.setState({isCategoriesOpen: !this.state.isCategoriesOpen,})
    }
    hotelGroupClick = () => {
        this.setState({isHotelGroupOpen: !this.state.isHotelGroupOpen,})
    }

    render() {
        return (
            <React.Fragment>
                <div className="w-100 filter">
                    <div className="border-theme col-12 mt-3 py-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <Button className="btn-reset text-dark">Filters</Button>
                            <Button
                                className={"btn-reset " + ((this.state.skeleton === true) ? "d-none" : "")}
                                onClick={() => this.props.resetFilters()}
                                intent={Intent.PRIMARY}>Reset
                                all
                            </Button>
                        </div>
                    </div>

                    <div className="border-theme col-12 mt-3 pb-1 font-20">
                        {(this.state.skeleton === true) ?
                            <div
                                className="text-left d-flex justify-content-between text-dark pr-2 align-items-center part_title">
                                <h3>Price</h3>
                                <Spinner intent={Intent.NONE} size={20} value={null}/>
                            </div>
                            :

                            <h3>Price</h3>

                        }
                        <Collapse isOpen={this.state.isPriceOpen} keepChildrenMounted={this.state.keepChildrenMounted}>
                            <div className="my-4 px-4">
                                {this.state.isPriceOpen ?
                                    <InputRange
                                        className="my-4"
                                        minValue={this.state.price.min}
                                        maxValue={this.state.price.max}
                                        formatLabel={value => `₹ ${value}`}
                                        value={this.state.filters.price}
                                        onChange={(e) => this.ChangeSlider(e)}
                                        onChangeComplete={() => {
                                            this.props.updateFilters(this.state.filters)
                                        }}
                                    />
                                    :
                                    ""
                                }

                            </div>
                        </Collapse>
                    </div>

                    <div className="border-theme col-12 mt-3 pb-1 font-20">
                        {(this.state.skeleton === true) ?
                            <div
                                className="d-flex justify-content-between text-dark pr-2 align-items-center part_title">
                                <h3>Amenities</h3>
                                <Spinner intent={Intent.NONE} size={20} value={null}/>
                            </div>
                            :
                            <div
                                onClick={this.AmenitiesClick}
                                className="d-flex justify-content-between text-dark pr-2 align-items-center part_title">
                                <h3>Amenities</h3>
                                {this.state.isAmenitiesOpen ? "-" : "+"}
                            </div>
                        }

                        <Collapse isOpen={this.state.isAmenitiesOpen}
                                  keepChildrenMounted={this.state.keepChildrenMounted}>
                            <div className="mt-3">
                                {
                                    this.state.amenities.map((amenity, key) => {
                                        return <Amenity amenity={amenity}
                                                        selectedAmenities={this.state.filters.amenities}
                                                        updateAmenities={(amenityList) => this.updateAmenities(amenityList)}
                                                        key={key}/>
                                    })
                                }
                            </div>
                        </Collapse>
                    </div>

                    <div className="border-theme col-12 mt-3 pb-1 font-20">

                        {(this.state.skeleton === true) ?
                            <div
                                className="text-left d-flex justify-content-between text-dark pr-2 align-items-center part_title">
                                <h3>Categories</h3>
                                <Spinner intent={Intent.NONE} size={20} value={null}/>
                            </div>
                            :
                            <div
                                onClick={this.CategoriesClick}
                                className="d-flex justify-content-between text-dark pr-2 align-items-center part_title">
                                <h3>Categories</h3>
                                {this.state.isCategoriesOpen ? "-" : "+"}
                            </div>
                        }
                        <Collapse isOpen={this.state.isCategoriesOpen}
                                  keepChildrenMounted={this.state.keepChildrenMounted}>
                            <div className="mt-3">
                                {
                                    (this.state.categories !== undefined && Array.isArray(this.state.categories)) ?

                                        this.state.categories.map((category, key) => {

                                            if (category === '' || category === null) {
                                                return "";
                                            }
                                            return <Category category={category} key={key}
                                                             selectedCategories={this.state.filters.categories}
                                                             updateCategories={(categoryList) => this.updateCategories(categoryList)}/>
                                        })
                                        : ""
                                }
                            </div>
                        </Collapse>
                    </div>

                    <div className="border-theme col-12 mt-3 pb-1 font-20">

                        {(this.state.skeleton === true) ?
                            <div
                                className="text-left d-flex justify-content-between text-dark pr-2 align-items-center part_title">
                                <h3>Hotel Group</h3>
                                <Spinner intent={Intent.NONE} size={20} value={null}/>
                            </div>
                            :
                            <div
                                onClick={this.hotelGroupClick}
                                className="d-flex justify-content-between text-dark pr-2 align-items-center part_title">
                                <h3>Hotel Group</h3>
                                {this.state.isHotelGroupOpen ? "-" : "+"}
                            </div>
                        }
                        <Collapse isOpen={this.state.isHotelGroupOpen}
                                  keepChildrenMounted={this.state.keepChildrenMounted}>
                            <div className="mt-3">
                                {
                                    this.state.hotelGroup.map((hotelGroup, key) => {

                                        if (hotelGroup === '' || hotelGroup === null) {
                                            return "";
                                        }

                                        return <HotelGroup hotelGroup={hotelGroup} key={key}
                                                           selectedHotelGroups={this.state.filters.hotel_group}
                                                           updateHotelGroups={(hotelGroupList) => this.updateHotelGroups(hotelGroupList)}/>
                                    })
                                }
                            </div>
                        </Collapse>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}