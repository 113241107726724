import React from 'react';
import SingleHotel from './SingleHotel';
import no_property from '../../core/Images/svg/no_property.svg';

export default class HotelList extends React.Component {

    constructor(props) {
        super(props);

        this.state = this.defaultProps();
    }

    defaultProps = () => {

        return {
            hotels: [],
            skeleton: this.props.skeleton,
        };
    }

    componentWillReceiveProps(nextProps) {

        // if (nextProps.listData.length > 0) {
        this.setState({
            hotels: nextProps.listData,
            skeleton: nextProps.skeleton,
        })
        // }
    }


    render() {
        return (
            <React.Fragment>

                <div className="PlaceList pt-3">
                    <div className="col-12">
                        <div className="row place-category">
                            <div className="col-sm-12 col-12">
                                {(this.state.skeleton === true) ?
                                    <React.Fragment>
                                        <SingleHotel hotel={{}} skeleton={true} key={1000000001}/>
                                        <SingleHotel hotel={{}} skeleton={true} key={1000000002}/>
                                        <SingleHotel hotel={{}} skeleton={true} key={1000000003}/>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>

                                        {
                                            (this.state.hotels.length === 0) ?

                                                <div className="container text-center text-muted">
                                                    <div className="not-found border">
                                                        <center>
                                                            <img
                                                                src={no_property}
                                                                alt={"notFound"}
                                                                className="notFound"
                                                                style={{width: '50%'}}
                                                            />
                                                            <h3
                                                                className="mt-xl-4 mt-lg-4 mt-md-4 mt-sm-3">
                                                                No Results Found<br/>
                                                                there are no hotels in your search criteria.
                                                            </h3>
                                                            <a
                                                                className="bp3-button bp3-intent-primary mt-2"
                                                                href="#!"
                                                                onClick={this.props.resetFilters}
                                                            >
                                                                Reset Filters
                                                            </a>
                                                        </center>
                                                    </div>
                                                </div>
                                                :
                                                this.state.hotels.map((hotel, key) => {

                                                    return <SingleHotel key={key + hotel.id + hotel.price} hotel={hotel}
                                                                        skeleton={this.state.skeleton}
                                                                        parentState={this.props.parentState}/>

                                                })
                                        }
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>

        );
    }


}