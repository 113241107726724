import React from 'react';
import Select from 'react-select';
import {fieldValidation, renderErrorComponent} from "../../core/Helpers/helper";

export const status = [
    {value: 'Mr', label: 'Mr', gender: "male"},
    {value: 'Mrs', label: 'Mrs', gender: "female"},
    {value: 'Miss', label: 'Miss', gender: "female"}
]

export default class PassengerDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.defaultProps();
    }

    defaultProps = () => {
        return {
            room: this.props.room,
            roomIndex: this.props.roomIndex,
            skeleton: this.props.skeleton,
            errors: this.props.errors,
        };
    }


    componentWillReceiveProps(nextProps) {

        this.setState({
            room: nextProps.room,
            roomIndex: nextProps.roomIndex,
            skeleton: nextProps.skeleton,
            errors: nextProps.errors,
        });
    }

    updateGuestDetails = (e, passengerIndex, isChildPassenger, fieldName, validationRule = []) => {

        let value = (e.target === undefined) ? e.value : e.target.value;

        let room = this.state.room;
        let gender = null;
        let guestDetails = room.guest_details;

        if (validationRule.length > 0) {

            let validateField = fieldValidation(value, fieldName, validationRule);

            let errors = this.state.errors;

            if (errors[this.state.roomIndex] !== undefined) {

                if (errors[this.state.roomIndex][passengerIndex] !== undefined) {
                    if (errors[this.state.roomIndex][passengerIndex][fieldName] !== undefined) {
                        Object.assign(errors[this.state.roomIndex][passengerIndex], {[fieldName]: validateField.errors});
                    } else {
                        Object.assign(errors[this.state.roomIndex][passengerIndex], {[fieldName]: validateField.errors});
                    }
                } else {
                    Object.assign(errors[this.state.roomIndex], {[passengerIndex]: {[fieldName]: validateField.errors}});
                }
            } else {
                Object.assign(errors, {[this.state.roomIndex]: {[passengerIndex]: {[fieldName]: validateField.errors}}});
            }

            this.setState({
                errors
            });

            /*if (validateField.status === false) {
                return false;
            }*/
        }

        if (fieldName === 'title') {

            gender = e.gender;
        }
        if (guestDetails[passengerIndex] === undefined) {
            guestDetails[passengerIndex] = {
                title: null,
                gender: null,
                first_name: null,
                last_name: null,
                age: (isChildPassenger === true) ? process.env.REACT_APP_HOTEL_MAX_CHILD_AGE : process.env.REACT_APP_HOTEL_MAX_ADULT_AGE,
                pax_type: (isChildPassenger === true) ? "Child" : "Adult",
                nationality: 'Indian',
            };

        }

        Object.assign(guestDetails[passengerIndex], {[fieldName]: value});

        if (gender !== null) {
            Object.assign(guestDetails[passengerIndex], {gender: gender});
        }

        Object.assign(room, {guest_details: guestDetails});

        // this.props.updateRoomDetails();

    }

    render() {
        if (this.state.skeleton === true) {
            return (
                <div className="col-12">
                    <span className="room bp3-skeleton">Room - 01</span>

                    <div className="row">
                        <div className="col-md-3 col-sm-12 col-12 mt-4 d-flex align-items-center">
                            <span className="room bp3-skeleton">Adult 01</span>
                        </div>
                        <div className="col-md-3 col-sm-12 col-12 mt-4">
                            <Select
                                placeholder={"Mr/Miss"}
                                className={"bp3-skeleton"}
                                options={status}
                                isSearchable={false}
                            />
                        </div>
                        <div className="col-md-3 col-sm-12 col-12 mt-4">
                            <input
                                type="text"
                                className="bp3-input bp3-skeleton"
                                required
                            />
                        </div>
                        <div className="col-md-3 col-sm-12 col-12 mt-4">
                            <input
                                type="text"
                                className="bp3-input bp3-skeleton"
                                required
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 col-sm-12 col-12 mt-4 d-flex align-items-center">
                            <span className="room bp3-skeleton">Adult 01</span>
                        </div>
                        <div className="col-md-3 col-sm-12 col-12 mt-4">
                            <Select
                                placeholder={"Mr/Miss"}
                                className={"bp3-skeleton"}
                                options={status}
                                isSearchable={false}
                            />
                        </div>
                        <div className="col-md-3 col-sm-12 col-12 mt-4">
                            <input
                                type="text"
                                className="bp3-input bp3-skeleton"
                                required
                            />
                        </div>
                        <div className="col-md-3 col-sm-12 col-12 mt-4">
                            <input
                                type="text"
                                className="bp3-input bp3-skeleton"
                                required
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 col-sm-12 col-12 mt-4 d-flex align-items-center">
                            <span className="room bp3-skeleton">Adult 01</span>
                        </div>
                        <div className="col-md-3 col-sm-12 col-12 mt-4">
                            <Select
                                placeholder={"Mr/Miss"}
                                className={"bp3-skeleton"}
                                options={status}
                                isSearchable={false}
                            />
                        </div>
                        <div className="col-md-3 col-sm-12 col-12 mt-4">
                            <input
                                type="text"
                                className="bp3-input bp3-skeleton"
                                required
                            />
                        </div>
                        <div className="col-md-3 col-sm-12 col-12 mt-4">
                            <input
                                type="text"
                                className="bp3-input bp3-skeleton"
                                required
                            />
                        </div>
                    </div>

                </div>
            )
        }
        let passengerCount = parseInt(this.state.room.adults) + parseInt(this.state.room.children);

        let passengers = [];

        for (let i = 1; i <= passengerCount; i++) {
            if (i <= this.state.room.adults) {
                passengers.push({index: i, type: "Adult", isChild: false});
            } else {
                passengers.push({index: i, type: "Child", isChild: true});
            }
        }


        return (
            <div className="col-12">
                <h3 className="room">Room - {this.state.roomIndex + 1}</h3>

                {passengers.map((passenger, key) => {

                    return <div className="row" key={passenger.type + key}>
                        {
                            passenger.isChild === true ?
                                <div className="col-lg-3 col-md-6 col-sm-12 col-12 mt-4">
                                    <h4>{passenger.type} {passenger.index - this.state.room.adults}</h4>
                                    <span
                                        className="font-10">(age below {parseInt(process.env.REACT_APP_HOTEL_MAX_CHILD_AGE) + 1} Years)</span>
                                </div>
                                :
                                <div className="col-lg-3 col-md-6 col-sm-12 col-12 mt-4 d-flex align-items-center">
                                    <h4>{passenger.type} {passenger.index}</h4>
                                </div>
                        }


                        <div className="col-lg-3 col-md-6 col-sm-12 col-12 mt-4">
                            <Select
                                placeholder={"Mr/Miss"}
                                onChange={(value, passengerIndex, isChildPassenger, fieldName, validationRule) => this.updateGuestDetails(value, key, passenger.isChild, "title", ['required'])}
                                options={status}
                                isSearchable={false}
                            />
                            {(this.state.errors[this.state.roomIndex] !== undefined) ? (this.state.errors[this.state.roomIndex][key] !== undefined) ? (this.state.errors[this.state.roomIndex][key]["title"] !== undefined) ? renderErrorComponent(this.state.errors[this.state.roomIndex][key]["title"]) : "" : "" : ""}
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 col-12 mt-4">
                            <input
                                type="text"
                                className="bp3-input"
                                onChange={(e, passengerIndex, isChildPassenger, fieldName, validationRule) => this.updateGuestDetails(e, key, passenger.isChild, "first_name", ['required', 'min,3'])}
                                required
                            />
                            <label className="form-control-placeholder">First Name</label>
                            {(this.state.errors[this.state.roomIndex] !== undefined) ? (this.state.errors[this.state.roomIndex][key] !== undefined) ? (this.state.errors[this.state.roomIndex][key]["first_name"] !== undefined) ? renderErrorComponent(this.state.errors[this.state.roomIndex][key]["first_name"]) : "" : "" : ""}
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 col-12 mt-4">
                            <input
                                type="text"
                                onChange={(e, passengerIndex, isChildPassenger, fieldName, validationRule) => this.updateGuestDetails(e, key, passenger.isChild, "last_name", ['required', 'min,3'])}
                                className="bp3-input"
                                required
                            />
                            <label className="form-control-placeholder">Last Name</label>
                            {(this.state.errors[this.state.roomIndex] !== undefined) ? (this.state.errors[this.state.roomIndex][key] !== undefined) ? (this.state.errors[this.state.roomIndex][key]["last_name"] !== undefined) ? renderErrorComponent(this.state.errors[this.state.roomIndex][key]["last_name"]) : "" : "" : ""}

                        </div>
                    </div>
                })}
            </div>
        )
    }
}