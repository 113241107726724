import React from 'react';
import SearchBar from "../SearchBar";
import PopularCityList from './components/PopularCityList';
import RecentlyViewed from './components/RecentlyViewed';

export default class Home extends React.Component {

    constructor(props) {
        super(props);
        this.state = {cityData: null}
    }

    setSearchByPopularCityData = (data) => {
        this.setState({cityData: data});
    }

    render() {
        return (
            <React.Fragment>
                <main>
                    <div className="hotel-strips text-center">
                        <div className="container">
                            <div className="main-heading">
                                <h1>Pamper yourself with the comfort!  Enjoy the Luxury</h1>
                            </div>
                            <div className="stripe-wrapper">
                                <SearchBar showRecentSearches={true} searchCityData={this.state.cityData}/>
                            </div>
                        </div>
                    </div>

                    <section className="Hotel_Destination py-5">
                        <div className="container">
                            <PopularCityList
                                setSearchByPopularCityData={(data) => this.setSearchByPopularCityData(data)}
                            />
                        </div>
                    </section>

                    <section className="Recently_Viewed py-5 bg-lightgray">
                        <div className="container">
                            <RecentlyViewed/>
                        </div>
                    </section>
                    <div id="cover"></div>
                </main>
            </React.Fragment>
        );
    }
}