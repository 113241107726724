import React from 'react';
import TopBar from "../core/components/TopBar";
// import GoogleMapReact from 'google-map-react';
import {ssoInstance} from "gg-react-utilities";
import Config from "../Config";
import {Card} from "@blueprintjs/core";
import NoHotel from '../core/Images/svg/no_hotel.svg';
import {MapMarker} from '../core/Helpers/helper';
import GoogleApiWrapper from './../HotelDetails/components/Maps';
import {Helper} from 'gg-react-utilities';

export default class BookingDetail extends React.Component {

    constructor(props) {
        super(props);

        this.state = this.defaultProps();
    }

    defaultProps = () => {
        return {
            bookingId: null,
            bookingDetails: null,
            skeleton: true,
        };
    }

    componentDidMount() {
        const {bookingId} = this.props.match.params;

        this.setState({bookingId}, () => {
            this.getBookingDetails();
        });
    }

    getBookingDetails = () => {


        const ROOT_URL = Config.API_URL;
        const GET_BOOKING_DETAIL_API = `api/booking/${this.state.bookingId}/detail`;
        const METHOD = 'get';
        ssoInstance({
            baseURL: `${ROOT_URL}`,
            url: `${GET_BOOKING_DETAIL_API}`,
            method: METHOD,
        }).then(response => {
            // do you have to `parse`? I don't remember...
            if (response && response.data && response.data.data) {

                this.setState({
                    bookingDetails: response.data.data,
                    skeleton: false,
                });
            }
        }).catch(error => {
            // whoops! what now?
        });
    };

    render() {

        var dateFormatOptions = {weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'};

        if (this.state.skeleton === true) {
            return <div className="container">
                <TopBar
                    title={this.state.skeleton === true ? "Loading..." : "Booking Details"}/>
            </div>
        }
        let totalGuests = 0;
        // let nearbyLocationCount = this.state.nearbyLocations.length;

        this.state.bookingDetails.booking_rooms.map(function (room) {
            totalGuests += (room.adults + room.children);
            return true;
        });
        return (
            <div className="container">
                <TopBar
                    title={this.state.skeleton === true ? "Loading..." : "Booking Details"}/>
                <section className="Booking-Details pb-5">
                    <Card>
                        <div className="container">
                            <div className="row">
                                <div className="col-12 px-0">
                                    <h1 className={"text-primary"}>Booking Confirmed</h1>
                                </div>
                                <div className="col-4 px-0">
                                    <h3 className="text-dark">Booking ID: {this.state.bookingDetails.id}</h3>
                                </div>
                                <div className="col-8 px-0">
                                    <h3 className="text-dark">Booking
                                        Date: {new Date(this.state.bookingDetails.created_at).toLocaleDateString('EN-IN', dateFormatOptions)}</h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4 px-0">
                                    <img src={NoHotel}
                                         alt={this.state.bookingDetails.booking_hotel_detail.name}/>
                                </div>
                                <div className="col-8 px-0">
                                    <div className="row">
                                        <div className="col-12">
                                            <h2 className="text-dark">
                                                <b>{this.state.bookingDetails.booking_hotel_detail.name}</b></h2>
                                            <span>{this.state.bookingDetails.booking_hotel_detail.address}</span><br/>
                                            <span>Getting there: </span><a
                                            href={"https://maps.google.com/?q= " + this.state.bookingDetails.booking_hotel_detail.latitude + "," + this.state.bookingDetails.booking_hotel_detail.longitude + ""}
                                            className={"text-primary"} target="_blank">Show directions</a>
                                            <br/>
                                        </div>
                                        <div className="col-6">
                                            <h4 className="mb-0">Check in</h4>
                                            <h3 className="text-dark mt-0">
                                                <b>{this.state.bookingDetails.checkin_date} {this.state.bookingDetails.checkin_time}</b>
                                            </h3>
                                        </div>
                                        <div className="col-6">
                                            <h4 className="mb-0">Check out</h4>
                                            <h3 className="text-dark mt-0">
                                                <b>{this.state.bookingDetails.checkout_date} {this.state.bookingDetails.checkout_time}</b>
                                            </h3>
                                        </div>
                                        <div className="col-2">
                                            {this.state.bookingDetails.booking_totals.map((booking) => {
                                                if (booking.code === "grand_total") {
                                                    return <h1
                                                        className="text-primary">{Helper.formatMoney(booking.value, true, false)}</h1>
                                                }
                                                return ""
                                            })}

                                        </div>
                                        <div className="col-10">
                                            <h4 className="text-dark">{this.state.bookingDetails.booking_rooms.length + ' Room, ' + totalGuests + " Guest"}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            <div className="row">
                                <div className="col-12">
                                    <h4><b>Traveller Details:</b></h4>
                                    <table>
                                        <tbody>
                                        {this.state.bookingDetails.booking_rooms.map((room) => {
                                            return room.guest_details.map((guest) => {
                                                return <tr>
                                                    <td>{guest.title} {guest.first_name} {guest.last_name}</td>
                                                    <td className="px-4">...........................................................................................................................</td>
                                                    <td>{guest.pax_type}</td>
                                                </tr>
                                            });
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <hr/>
                            <div className="row">
                                <div className="col-12">
                                    <h4><b>Location:</b></h4>
                                    <GoogleApiWrapper skeleton={this.state.skeleton}
                                                      hotelName={(this.state.bookingDetails !== null) ? this.state.bookingDetails.booking_hotel_detail.name : "Loading Details..."}
                                                      nearbyLocations={[]}
                                                      location={(this.state.bookingDetails !== null) ? {
                                                          latitude: this.state.bookingDetails.booking_hotel_detail.latitude,
                                                          longitude: this.state.bookingDetails.booking_hotel_detail.longitude
                                                      } : {latitude: 0, longitude: 0}}/>

                                </div>
                            </div>
                            <hr/>
                            <div className="row">
                                <div className="col-12">
                                    <h4><b>Room Detail:</b></h4>

                                    {this.state.bookingDetails.booking_rooms.map((room, roomNoIndex) => {

                                        let inclusions = room.inclusions.split("|#|");

                                        return <table key={roomNoIndex}>
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <b>Room {roomNoIndex + 1}</b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Number of Guests : Adults - {room.adults}, Child - {room.children}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>inclusions : </p>
                                                    {inclusions.map((inclusion, key) => {
                                                        if (inclusion === '' || inclusion === null || inclusion === undefined) {
                                                            return false;
                                                        }
                                                        return <React.Fragment
                                                            key={key}>
                                                            <span> - {inclusion} </span>
                                                            <br/></React.Fragment>
                                                    })}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    })}
                                </div>
                            </div>
                            <hr/>
                            <div className="row">
                                <div className="col-12">
                                    <h4><b>Hotel Policy:</b></h4>

                                    {this.state.bookingDetails.booking_hotel_detail.policies.split("|#|").map((policy, key) => {

                                        return <React.Fragment
                                            key={key}>
                                            <span> - {policy} </span>
                                            <br/></React.Fragment>
                                    })}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <h4><b>Cancellation Policy:</b></h4>

                                    {this.state.bookingDetails.cancellation_policies.split("|#|").map((policy, key) => {

                                        return <React.Fragment
                                            key={key}>
                                            <span> - {policy} </span>
                                            <br/></React.Fragment>
                                    })}
                                </div>
                            </div>
                        </div>
                    </Card>
                </section>
            </div>)
            ;

    }
}