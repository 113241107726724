import React from 'react';
import {Card, Elevation} from "@blueprintjs/core";
import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';


const style = {
    width: '100%',
    height: '100%'
}

export class Maps extends React.Component {


    constructor(props) {
        super(props);

        this.state = this.defaultProps();
    }

    defaultProps = () => {
        return {
            skeleton: this.props.skeleton,
            location: {
                lat: parseFloat(this.props.location.latitude),
                lng: parseFloat(this.props.location.longitude),
            },
            hotelName: this.props.hotelName,
            nearbyLocations: this.props.nearbyLocations,
            zoom: 15,
        };
    }


    componentWillReceiveProps(nextProps) {

        this.setState({
            skeleton: nextProps.skeleton,
            hotelName: nextProps.hotelName,
            nearbyLocations: nextProps.nearbyLocations,
            location: {
                lat: parseFloat(nextProps.location.latitude),
                lng: parseFloat(nextProps.location.longitude),
            },

        })
    }

    render() {
        if (this.state.skeleton === true) {
            return (
                <React.Fragment>
                    <div className="card-header">
                        <h2>Location on Map</h2>
                    </div>
                    <div className={'card-body'}>
                        <div className={"row"}>
                            <div className={'col-12'}>
                                <h1 className={"text-center"}>Loading Map....</h1>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )
        }
        let nearbyLocationCount = this.state.nearbyLocations.length;
        return (
            <React.Fragment>

                <div className="card-header">
                    <h2>Location on Map</h2>
                </div>
                <div className={'card-body'}>
                    <div className={"row"}>
                        <div className={(nearbyLocationCount > 0) ? 'col-12 col-md-8' : 'col-12'}>

                            <div style={{height: '40vh', width: '100%', paddingTop: "5px"}}>
                                <Map
                                    style={style}
                                    google={this.props.google}
                                    initialCenter={this.state.location}
                                    zoom={this.state.zoom}>
                                    <Marker
                                        position={this.state.location}/>
                                </Map>
                            </div>
                        </div>
                        <div className={(nearbyLocationCount > 0) ? 'col-12 col-md-4 mt-2 map_data' : 'd-none'} style={{
                            maxHeight: '380px',
                            overflow: 'scroll'
                        }}>
                            <h5>important landmark nearby</h5>
                            {this.state.nearbyLocations.map((location) => {
                                return <h6><b>{location.Name}</b> &nbsp;&nbsp;
                                    <strong>({location.Distance} Km)</strong></h6>
                            })}
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    }
}

export default GoogleApiWrapper({
    apiKey: (process.env.REACT_APP_GOOGLE_MAP_AUTH_KEY)
})(Maps)