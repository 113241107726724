import React from 'react';
import {fieldValidation, renderErrorComponent} from '../../core/Helpers/helper';

export default class ContactDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.defaultProps();
    }

    defaultProps = () => {
        return {
            skeleton: this.props.skeleton,
            contactDetails: this.props.contactDetails,
            errors: this.props.errors,
        };
    }

    componentWillReceiveProps(nextProps) {

        this.setState({
            skeleton: nextProps.skeleton,
            contactDetails: nextProps.contactDetails,
            errors: nextProps.errors,
        });
    }

    updateContactDetails = (e, fieldName, validationRule = []) => {

        let value = (e.target === undefined) ? e.value : e.target.value;

        let contactDetails = this.state.contactDetails;

        if (validationRule.length > 0) {

            let validateField = fieldValidation(value, fieldName, validationRule);

            let errors = this.state.errors;

            Object.assign(errors, {[fieldName]: validateField.errors});

            this.setState({
                errors
            });

            /*if (validateField.status === false) {
                return false;
            }*/
        }
        Object.assign(contactDetails, {[fieldName]: value});

    }


    render() {
        if (this.state.skeleton === true) {
            return (
                <div className="col-12">
                    <span className="bp3-skeleton">Contact Details</span>
                    <span className="bp3-skeleton">All communication from Hotel / Provider will be sent on the shared details</span>
                    <div className="row">
                        <div className="col-sm-6 col-12 mt-4">
                            <input
                                type="text"
                                className="bp3-input bp3-skeleton"
                                required
                            />
                        </div>
                        <div className="col-sm-6 col-12 mt-4">
                            <input
                                type="text"
                                name="contact"
                                className="bp3-input bp3-skeleton"
                                required
                            />
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className="col-12">
                <h3>Contact Details</h3>
                <h6>All communication from Hotel / Provider will be sent on the shared details</h6>
                <div className="row">
                    <div className="col-12 mt-4">
                        <input
                            type="text"
                            id="contact_email"
                            className="bp3-input"
                            required
                            onChange={(e, fieldName) => this.updateContactDetails(e, "email", ['required', 'email'])}

                        />
                        <label className="form-control-placeholder">Email Address</label>
                        {(this.state.errors.email !== undefined) ? renderErrorComponent(this.state.errors.email) : ""}
                    </div>
                    <div className="col-md-4 col-sm-12 col-12 mt-4">
                        <input
                            type="text"
                            name="contact_phone_country_code"
                            className="bp3-input"
                            required
                            onChange={(e, fieldName) => this.updateContactDetails(e, "phone_country_code", ['required', 'min,2', 'max,4'])}
                        />
                        <label className="form-control-placeholder">Country Code</label>
                        {(this.state.errors.phone_country_code !== undefined) ? renderErrorComponent(this.state.errors.phone_country_code) : ""}

                    </div>
                    <div className="col-md-12 col-sm-12 col-12 mt-4">
                        <input
                            type="text"
                            name="contact_mobile"
                            className="bp3-input"
                            required
                            onChange={(e, fieldName) => this.updateContactDetails(e, "mobile", ['required', 'numeric', 'length,10'])}
                        />
                        <label className="form-control-placeholder">Contact Number</label>
                        {(this.state.errors.mobile !== undefined) ? renderErrorComponent(this.state.errors.mobile) : ""}

                    </div>
                </div>
            </div>
        )
    }
}