import React from 'react';
import {Card, Elevation, Icon} from "@blueprintjs/core";

export default class Policies extends React.Component {


    constructor(props) {
        super(props);

        this.state = this.defaultProps();
    }

    defaultProps = () => {
        return {
            skeleton: this.props.skeleton,
            hotelDetails: this.props.hotelDetails,
        };
    }


    componentWillReceiveProps(nextProps) {

        this.setState({
            skeleton: nextProps.skeleton,
            hotelDetails: nextProps.hotelDetails,
        })
    }

    render() {
        if (this.state.skeleton === true) {
            return <React.Fragment>
                <Card interactive={false} elevation={Elevation.TWO} className="col-12">
                    <div className="card-header">
                        <h2>Hotel Policies</h2>
                    </div>
                    <div className={'card-body'}>
                        <div className={"row policy"}>
                            <div className={'col-lg-5 col-md-6 col-sm-6 col-12'}>
                                <h2 className={"bp3-skeleton"}><Icon icon="time" iconSize={25}/> Checkin : 11:00 AM</h2>
                            </div>
                            <div className={'col-lg-5 col-md-6 col-sm-6 col-12'}>
                                <h2 className={"bp3-skeleton"}><Icon icon="time" iconSize={25}/> Checkin : 11:00 AM</h2>
                            </div>
                            <div className={'col-12 mt-s-4 text-justify'}>

                                <span className={"bp3-skeleton"}>eyJpdiI6Imt5Q3ptdGoxTEZTZE9wRVZrdnp vRVE9PSIsInZ hbHVlIjoidlN5bDgyZTcxMnR1b1dmTFVjUXFydz09I iwibWFjIjoiZDFhM2FjZjY wZTVlZmU3NmExNGI zM2FiYjZlYmMxZTY0NjA4ZTh</span>
                                <span className={"bp3-skeleton"}>eyJpdiI6Imt5Q3ptdGoxTEZTZE9wRVZrdnp vRVE9PSIsInZ hbHVlIjoidlN5bDgyZTcxMnR1b1dmTFVjUXFydz09I iwibWFjIjoiZDFhM2FjZjY wZTVlZmU3NmExNGI zM2FiYjZlYmMxZTY0NjA4ZTh hNDM2NTdmZWE2 MWVmYzg3Njc3ZjU1MDMyOSJ9</span>
                                <span className={"bp3-skeleton"}>eyJpdiI6Imt5Q3ptdGoxTEZTZE9wRVZrdnp vRVE9PSIsInZ hbHVlIjoidlN5bDgyZTcxMnR1b1dmTFVjUXFydz09I iwibWFjIjoiZDFhM2FjZjY wZTVlZmU3NmExNGI zM2FiYjZlYmMxZTY0NjA4ZTh hNDM2NTdmZWE2 MWVmYzg3Njc3ZjU1MDMyOSJ9</span>
                                <span className={"bp3-skeleton"}>eyJpdiI6Imt5Q3ptdGoxTEZTZE9wRVZrdnp vRVE9PSIsInZ hbHVlIjoidlN5bDgyZTcxMnR1b1dmTFVjUXFydz09I iwibWFjIjoiZDFhM2FjZjY wZTVlZmU3NmExNGI zM2FiYjZlYmMxZTY0NjA4ZTh hNDM2NTdmZWE2 MWVmYzg3Njc3ZjU1MDMyOSJ9</span>
                                <span className={"bp3-skeleton"}>eyJpdiI6Imt5Q3ptdGoxTEZTZE9wRVZrdnp vRVE9PSIsInZ hbHVlIjoidlN5bDgyZTcxMnR1b1dmTFVjUXFydz09I iwibWFjIjoiZDFhM2FjZjY wZTVlZmU3NmExNGI zM2FiYjZlYmMxZTY0NjA4ZTh hNDM2NTdmZWE2 MWVmYzg3Njc3ZjU1MDMyOSJ9</span>

                            </div>
                        </div>
                    </div>
                </Card>
            </React.Fragment>
        }
        return (
            <React.Fragment>
                <Card interactive={false} elevation={Elevation.TWO} className="col-12">
                    <div className="card-header">
                        <h2>Hotel Policies</h2>
                    </div>
                    <div className={'card-body'}>
                        <div className={"row policy"}>
                            <div className={'col-lg-5 col-md-6 col-sm-6 col-12'}>
                                <h2><Icon icon="time" iconSize={25}/> Checkin : {this.state.hotelDetails.checkin_time}
                                </h2>
                            </div>
                            <div className={'col-lg-5 col-md-6 col-sm-6 col-12'}>
                                <h2><Icon icon="time" iconSize={25}/> Checkout : {this.state.hotelDetails.checkout_time}
                                </h2>
                            </div>
                            <div className={'col-12 mt-s-4 text-justify'}>
                                {this.state.hotelDetails.hotel_policies.map((policy,key) => {
                                    return <React.Fragment key={key}><span
                                        className={"mt-2"}>&bull; {policy}</span><br/><br/></React.Fragment>
                                })}
                            </div>
                        </div>
                    </div>
                </Card>
            </React.Fragment>
        )
    }
}