import React from 'react';
import {Button, Intent, Tooltip, Position, Tag} from "@blueprintjs/core";
import ReactStars from 'react-stars'
import {Helper} from 'gg-react-utilities';
import {base64_url_encode} from '../../core/components/QueryString';
// import NoHotel from '../../core/Images/svg/no_hotel.svg';
import DefaultAmenity from '../../core/Images/png/default_amenity.png';

export default class SingleHotel extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};
    }


    componentDidMount() {
        this.setState(this.props.hotel)
    }

    redirectToHotelDetails = () => {

        const formData = new FormData();

        formData.append('locationLabel', (this.props.parentState.locationLabel.length > 20) ? this.props.parentState.locationLabel.substring(0, 20) + "..." : this.props.parentState.locationLabel);
        formData.append('startDate', this.props.parentState.startDate.getTime());
        formData.append('endDate', this.props.parentState.endDate.getTime());
        formData.append('rooms', base64_url_encode(JSON.stringify(this.props.parentState.rooms)));
        formData.append('location', this.props.parentState.location);
        formData.append('hotel', base64_url_encode(this.state.id));
        formData.append('provider', this.state.provider);

        const queryString = new URLSearchParams(formData).toString();

        window.open(process.env.REACT_APP_URL + 'hotel?' + queryString, '_blank');

    }


    /*
    * address: "Candolim, Goa"
    amenities: [{id: 0, name: "Free Cancellation", icon: "default.png"}]
    category: ["Resort", "3 Star Hotels"]
    hotel_group: "Yatra Smart"
    hotel_name: "Sukhmantra Resort And Spa"
    id: "eyJpdiI6InlZSHVRWFAwdlQ2aDBFNkhWNnpVZUE9PSIsInZhbHVlIjoiaHk4SmRiMWJuN2N2ZlwvVzhQTU5OaUhqejNMc0JBQ244R2gxMDlWbUU4Z2JsWDk4WjZOZmVPTlZ3bkpGMmhHMk9DaXlJUVwvdjRRQ0JPaytkRDY5VHZEQT09IiwibWFjIjoiNWNlMTRmOTZiZWRmZTdkN2FjNWM2ZDYyOGQ4ZWRjNGZjMDU2YjI3Y2FjNzNkNGFhOWZhZjg0YWMxMDMyNGM5OSJ9"
    image: "Https://imgcld.yatra.com/ytimages/image/upload/t_hotel_yatra_city_desktop/v1409568933/domestic Hotels/hotels_goa/sukhmantra Resort And Spa/overview.jpg"
    price: 16000
    provider: "Bb"
    rooms_left: "20"
    rating: ""
    review: ""
    trip_advisor_rating: ""
    trip_advisor_total_review: ""
    */

    onHotelImageError = (e) => {
        this.setState({
            image: ''
        });
    }

    onAmenityImageError = (e, key) => {

        this.setState({
            amenities: Object.assign(this.state.amenities, Object.assign(this.state.amenities[key], {icon: DefaultAmenity}))
        });
    }

    render() {

        if (this.props.skeleton === true) {
            var amenities = [{name: "amenity"}, {name: "amenity"}, {name: "amenity"}];

            return (
                <React.Fragment key={this.props.key}>
                    <div className="place mb-3">
                        <div className={"row"}>
                            <div className="col-lg-4 col-sm-4 pr-sm-0">
                                <div className="hotel-img bp3-skeleton">
                                    <img src={(this.state.image !== undefined) ? this.state.image : ''}
                                         className={(this.state.image === undefined) ? "d-none" : "img-fluid"}
                                         alt=""
                                         onError={this.onHotelImageError}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-8 col-sm-8 col-12">
                                <div className="place-img">
                                    <div
                                        className="d-flex justify-content-between align-items-start">
                                        <div className="text-left">
                                            <h4 className="place-name bp3-skeleton">aaaaaaaaaaaaaaaaaaaaaaaaaaa</h4>
                                        </div>
                                    </div>
                                    <span
                                        className="place-type bp3-skeleton">aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa</span>
                                    <div className="d-sm-flex align-items-center mt-2 justify-content-between">
                                        <div
                                            className="d-flex align-items-center justify-content-start bp3-skeleton">
                                            <span
                                                className="viewed-rating">{(this.state.rating !== undefined && this.state.rating !== null && this.state.rating !== '') ? parseFloat(this.state.rating).toFixed(1) : "N/A"}</span>
                                            <ReactStars
                                                value={(this.state.rating !== undefined && this.state.rating !== null) ? parseFloat(this.state.rating).toFixed(1) : 0}
                                                count={5}
                                                size={20}
                                                edit={false}
                                                className="stars"
                                                color2={'#ffd700'}/>
                                        </div>


                                        <div className="d-flex flex-sm-column align-items-sm-end text-center-xs">
                                            <span className="place-price bold pt-0 bp3-skeleton">aaaaaaaaaaa</span>
                                            <br/>
                                            <span
                                                className="font-12 bp3-skeleton ">aaaaaaaaaaaaa</span>
                                        </div>
                                    </div>
                                    <div
                                        className="text-center d-sm-flex justify-content-between align-items-center pt-s-0">
                                        <div className="py-2 text-left d-flex align-items-center">
                                            {(amenities !== undefined) ?
                                                <React.Fragment>
                                                    {amenities.map((amenity, key) => {

                                                        if (key <= 5) {
                                                            return <React.Fragment key={key}>
                                                                <div className={"bp3-skeleton"}>
                                                                    <img src={amenity.icon}
                                                                         onError={(e, key1) => this.onAmenityImageError(e, key)}
                                                                         style={{
                                                                             height: "auto",
                                                                             width: "18px",
                                                                             cursor: "help",
                                                                             filter: "opacity(0.68)"
                                                                         }}
                                                                         className={"mx-2 mx-s-1 bp3-skeleton"}
                                                                         alt={"a"}
                                                                    />
                                                                </div>
                                                                &nbsp;
                                                            </React.Fragment>
                                                        }
                                                        return false;
                                                    })
                                                    }
                                                    {amenities.length > 3 ?
                                                        <span className="text-primary bp3-skeleton"
                                                              style={{cursor: "pointer"}}
                                                              onClick={() => this.redirectToHotelDetails()}> ( + {(this.state.amenities.length - 3 + " more")})</span>
                                                        : ""}
                                                </React.Fragment>
                                                :
                                                ""
                                            }
                                        </div>
                                        <Button
                                            text="Choose Room"
                                            intent={Intent.PRIMARY}
                                            className="btn-explore mt-1 bp3-skeleton"
                                            onClick={() => this.redirectToHotelDetails()}

                                        />
                                    </div>

                                    {this.state.rooms_left < 3 ?
                                        <Tag
                                            intent={Intent.WARNING}
                                            className="rounded-0"
                                        >
                                            {this.state.rooms_left} Room Left
                                        </Tag> : ""
                                    }

                                </div>
                            </div>
                            {/*<div className="col-lg-8 col-12">
                                <div className="place-img">
                                    <div
                                        className="d-flex justify-content-between align-items-start">
                                        <div className="text-left">
                                            <h4 className="place-name bp3-skeleton">{(this.state.hotel_name !== undefined) ? this.state.hotel_name : 'lorem Impsum site Emet'}</h4>
                                        </div>
                                    </div>
                                    <h6 className="place-type">{(this.state.address !== undefined) ? this.state.address : ''}</h6>
                                    <div className="d-flex align-items-center justify-content-between mt-2">
                                        <div
                                            className="d-flex align-items-center justify-content-start bp3-skeleton">
                                            <span className="viewed-rating">4.0</span>
                                            <ReactStars
                                                count={5}
                                                size={24}
                                                edit={false}
                                                color2={'#ffd700'}/>
                                        </div>
                                        <h6 className="place-days bp3-skeleton"><Icon icon="eye-open"
                                                                                      iconSize={18}
                                                                                      className="mr-1"/> 3.5/5
                                            Very Good</h6>
                                        <div className="d-flex flex-column align-items-end">
                                            <h3 className="place-price bold bp3-skeleton"> ₹ {(this.state.price !== undefined) ? this.state.price : '500000'}</h3>
                                        </div>
                                    </div>
                                    <div
                                        className="pt-2 text-center d-flex justify-content-between align-items-center">
                                        <div className="py-2 text-left">
                                            {
                                                amenities.map((amenity) => (

                                                    <React.Fragment>

                                                        <Icon icon="bookmark" iconSize={20}
                                                              className="mx-2 bp3-skeleton"/>
                                                        <span className={"bp3-skeleton"}>{amenity.name}</span>
                                                    </React.Fragment>
                                                ))

                                            }
                                        </div>
                                        <Button
                                            text="Choose Room"
                                            intent={Intent.PRIMARY}
                                            className="btn-explore mt-1 bp3-skeleton"
                                        />
                                    </div>
                                </div>
                            </div>*/}
                        </div>
                    </div>
                </React.Fragment>
            );
        }
        else {
            return (
                <React.Fragment key={this.props.key}>
                    <div className="place mb-3">
                        <div className="row">
                            <div className="col-lg-4 col-sm-4 pr-sm-0">
                                <div className="hotel-img">
                                    <img src={(this.state.image !== undefined) ? this.state.image : this.state.image}
                                         className={(this.state.image === undefined || this.state.image === '') ? "d-none" : "img-fluid"}
                                         onError={this.onHotelImageError}
                                         alt={(this.state.hotel_name !== undefined) ? this.state.hotel_name : ''}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-8 col-sm-8 col-12">
                                <div className="place-img">
                                    <div
                                        className="d-flex justify-content-between align-items-start">
                                        <div className="text-left">
                                            <h4 className="place-name">{(this.state.hotel_name !== undefined) ? this.state.hotel_name : ''}</h4>
                                        </div>
                                    </div>
                                    <h6 className="place-type">{(this.state.address !== undefined) ? this.state.address : ''}</h6>
                                    <div className="d-sm-flex align-items-center mt-2 justify-content-between">
                                        <div
                                            className="d-flex align-items-center justify-content-start">
                                            <span
                                                className="viewed-rating">{(this.state.rating !== undefined && this.state.rating !== null && this.state.rating !== '') ? parseFloat(this.state.rating).toFixed(1) : "N/A"}</span>
                                            <ReactStars
                                                value={(this.state.rating !== undefined && this.state.rating !== null) ? parseFloat(this.state.rating).toFixed(1) : 0}
                                                count={5}
                                                size={20}
                                                edit={false}
                                                className="stars"
                                                color2={'#F5A623'}/>
                                        </div>
                                        <h6 className="place-days">
                                            {(this.state.trip_advisor_rating !== undefined && this.state.trip_advisor_rating !== null && this.state.trip_advisor_rating !== '') ? this.state.trip_advisor_rating + " / " : ""}
                                            {(this.state.trip_advisor_total_review !== undefined && this.state.trip_advisor_total_review !== null && this.state.trip_advisor_total_review !== '') ? this.state.trip_advisor_total_review : ""}
                                            {(this.state.trip_advisor_rating_image_url !== undefined && this.state.trip_advisor_rating_image_url !== null && this.state.trip_advisor_rating_image_url !== '') ?
                                                <img src={this.state.trip_advisor_rating_image_url}
                                                     alt={this.state.trip_advisor_rating + " Rating"}
                                                     style={{height: '15px', width: '100px'}} className={"ml-2"}/> : ""}
                                        </h6>

                                        <div className="d-flex flex-sm-column align-items-sm-end text-center-xs">
                                            <h3 className="place-price bold pt-0"> {(this.state.price !== undefined) ? Helper.formatMoney(this.state.price, true, false) : ''}</h3>
                                            <span
                                                className="text-muted font-12">{(this.state.price_for !== undefined) ? this.state.price_for : ''}</span>
                                        </div>
                                    </div>
                                    <div
                                        className="text-center d-sm-flex justify-content-between align-items-center pt-s-0">
                                        <div className="py-2 text-left d-flex align-items-center">
                                            {(this.state.amenities !== undefined) ?
                                                <React.Fragment>
                                                    {this.state.amenities.map((amenity, key) => {

                                                        if (key <= 5) {
                                                            return <React.Fragment key={key}>
                                                                <Tooltip className="d-inline-flex"
                                                                         position={Position.BOTTOM}
                                                                         content={amenity.name}>
                                                                    <img src={amenity.icon}
                                                                         onError={(e, key1) => this.onAmenityImageError(e, key)}
                                                                         style={{
                                                                             height: "auto",
                                                                             width: "18px",
                                                                             cursor: "help",
                                                                             filter: "opacity(0.68)"
                                                                         }}
                                                                         className={"mx-2 mx-s-1"}
                                                                         alt={"a"}
                                                                    />
                                                                </Tooltip>
                                                            </React.Fragment>
                                                        }
                                                        return false;
                                                    })
                                                    }
                                                    {this.state.amenities.length > 3 ?
                                                        <span className="text-primary" style={{cursor: "pointer"}}
                                                              onClick={() => this.redirectToHotelDetails()}> ( + {(this.state.amenities.length - 3 + " more")})</span>
                                                        : ""}
                                                </React.Fragment>
                                                :
                                                ""
                                            }
                                        </div>
                                        <Button
                                            text="Choose Room"
                                            intent={Intent.PRIMARY}
                                            className="btn-explore mt-1"
                                            onClick={() => this.redirectToHotelDetails()}

                                        />
                                    </div>

                                    {this.state.rooms_left < 3 ?
                                        <Tag
                                            intent={Intent.WARNING}
                                            className="rounded-0"
                                        >
                                            {this.state.rooms_left} Room Left
                                        </Tag> : ""
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        }

    }
}