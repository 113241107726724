import React from 'react';
import {Button, Popover, Position} from '@blueprintjs/core';
import SingleRoomSection from './SingleRoomSection';
import {Intent} from "@blueprintjs/core/lib/esm/index";

const maxRoomsAllowed = 3;

export default class RoomSelection extends React.Component {


    constructor(props) {
        super(props);
        this.state = this.defaultProps(this.props);
    }

    componentWillReceiveProps(nextProps) {

        this.setState(this.defaultProps(nextProps));
    }

    defaultProps = (props) => {

        let totalGuests = 0;
        props.rooms.map(function (room) {
            totalGuests += (room.adults + room.children);
            return true;
        });

        return {
            rooms: props.rooms,
            totalGuests: totalGuests
        };
    }

    addRoom = () => {
        let data = {
            adults: 2,
            children: 0
        };
        let rooms = this.state.rooms;

        if (rooms.length > maxRoomsAllowed) {
            return false;
        }
        rooms.push(data);

        this.setState({
            rooms
        }, this.updateRoomGuests(0, this.defaultProps(this.props)));

    }

    deleteRoom = () => {
        let rooms = this.state.rooms;

        if (rooms.length <= 1) {
            return false;
        }
        rooms.pop();

        this.setState({
            rooms
        }, this.updateRoomGuests(0, this.defaultProps(this.props)));

    }

    updateRoomGuests = (roomNumber, guests) => {
        roomNumber = roomNumber - 1;
        let totalGuests = 0;
        let newRoomList = this.state.rooms.map((room, i) => {
            if (i === roomNumber) {
                room.adults = guests.adults;
                room.children = guests.children;
            }

            totalGuests += (room.adults + room.children);
            return room;
        });

        this.setState({
            room: newRoomList,
            totalGuests
        }, () => {
            this.props.updateRoomGuests(this.state.room);
        });
    }

    buildRoomsList = () => {

        return this.state.rooms.map((room, i) => {

            return <SingleRoomSection roomData={room} roomNumber={i + 1}
                                      updateRoomGuests={(room, guests) => this.updateRoomGuests(room, guests)}
                                      key={i}/>;
        });

    }

    render() {

        return (

            <Popover content={
                <div className="passengerInputWrapper">
                    <ul className="dropdown-menu passenger-dropdown-content dropdown-cart m-1">
                        {this.buildRoomsList()}
                    </ul>
                        <div className="d-flex align-items-center justify-content-between p-2 btn-box px-3">
                            <Button
                                text="Add Room"
                                intent={Intent.MINIMAL}
                                className="bp3-outline btn-sm text-left font-13"
                                onClick={() => this.addRoom()}
                            />
                            {(this.state.rooms.length > 1) ?
                                <Button
                                    text="Delete Room"
                                    intent={Intent.MINIMAL}
                                    className="bp3-outline btn-sm text-right font-13"
                                    onClick={() => this.deleteRoom()}
                                /> :
                                <Button
                                    text="Delete Room"
                                    intent={Intent.MINIMAL}
                                    className="bp3-outline btn-sm text-right font-13"
                                    disabled={true}
                                />
                            }
                        </div>
                </div>
            } position={Position.BOTTOM}>
                {this.props.isVertical === true ?
                    <div className="bp3-input-group">
                        <input type="text" className="bp3-input" dir="auto"
                               value={this.state.rooms.length + ' Room, ' + this.state.totalGuests + " Guest"}/>
                        <a href={'#!'} className="edit">Edit</a>
                    </div>
                    :
                    <span className="dropdown-toggle px-1 py-3" style={{cursor: 'pointer', color: '#383838'}}>
                        {this.state.rooms.length} Room, {this.state.totalGuests} Guest
                        <span className="bp3-icon-standard bp3-icon-chevron-down ml-3"></span>
                    </span>
                }
            </Popover>

        );
    }
}