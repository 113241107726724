import React from 'react';
import Select from 'react-select';
import {Card, Elevation, Button, Intent, Label} from "@blueprintjs/core";
import SearchBar from "../../SearchBar";
import ReactStars from 'react-stars'
import {Helper} from 'gg-react-utilities';

export default class BookNow extends React.Component {

    constructor(props) {
        super(props);

        this.state = this.defaultProps();
    }

    defaultProps = () => {
        return {
            searchTerms: this.props.searchTerms,
            hotelDetails: null,
            roomList: [],
            selectedRoom: this.props.selectedRoom,
        };
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.searchTerms !== undefined) {

            let searchTerms = {
                location: nextProps.searchTerms.location,
                locationLabel: nextProps.searchTerms.locationLabel,
                startDate: nextProps.searchTerms.startDate,
                endDate: nextProps.searchTerms.endDate,
                rooms: nextProps.searchTerms.rooms,
                hotel: nextProps.searchTerms.hotel,
                provider: nextProps.searchTerms.provider,
            };
            this.setState({
                searchTerms: searchTerms,
                hotelDetails: nextProps.hotelDetails,
                roomList: (nextProps.hotelDetails !== null) ? nextProps.hotelDetails.room_details : [],
                selectedRoom: (nextProps.selectedRoom !== null) ? nextProps.selectedRoom : null,
            });
        }
    }

    onRoomSelectChange = (selectedRoom) => {
        this.props.onRoomChange(selectedRoom);
        window.location = "#" + selectedRoom.room_code;
    }

    bookRoom = () => {
        this.props.bookRoom(this.state.selectedRoom);
    }

    getOptionValue = (option) => option.room_code;

    getOptionLabel = (option) => (option.type.length > 11 ? option.type.substring(0, 11) + "..." : option.type) + " [" + Helper.formatMoney(option.rate,true,false) + "]";

    render() {
        return (
            <React.Fragment>
                <Card interactive={false} elevation={Elevation.TWO} className="col-12">
                    <h3>{(this.state.hotelDetails) ? this.state.hotelDetails.hotel_name : "Loading..."}</h3>
                    <p>{(this.state.hotelDetails) ? this.state.hotelDetails.address : ""}</p>

                    <div
                        className={"align-items-center justify-content-start" + ((this.state.hotelDetails) ? (this.state.hotelDetails.rating !== null ? " d-flex" : " d-none") : " d-none")}>
                        <span
                            className="hotel-rating">{(this.state.hotelDetails) ? parseFloat(this.state.hotelDetails.rating).toFixed(1) : "N/A"}</span>
                        <ReactStars
                            value={(this.state.hotelDetails) ? parseFloat(this.state.hotelDetails.rating).toFixed(1) : 0}
                            count={5}
                            size={20}
                            edit={false}
                            className="stars"
                            color2={'#F5A623'}/>
                    </div>
                    {/*<div className="d-flex align-items-center justify-content-start mt-3">
                        <h6 className="hotel-review">
                            <img src={require('../images/trip.png')} alt="" className="img-fluid mr-2"/>
                            3.5/5 Very Good
                        </h6>
                    </div>*/}
                    <hr/>

                    <SearchBar isVertical={true} searchTerms={this.state.searchTerms}/>
                    <hr/>
                    <div className="col-12">
                        <div className="row d-flex flex-column">

                            {this.state.selectedRoom !== null ?
                                <React.Fragment>
                                    <Label>Select Room</Label>
                                    <Select
                                        name="rooms"
                                        defaultValue={(this.state.selectedRoom !== null) ? this.state.selectedRoom : ""}
                                        options={this.state.roomList}
                                        onChange={(value) => this.onRoomSelectChange(value)}
                                        placeholder={'Select Room'}
                                        getOptionValue={this.getOptionValue}
                                        getOptionLabel={this.getOptionLabel}

                                    />
                                </React.Fragment>
                                :
                                "Loading..."
                            }

                        </div>
                    </div>
                    <hr/>

                    <div className="col-12">
                        <div className="row d-flex align-items-center justify-content-between">
                            <h2>{this.state.selectedRoom !== null ? Helper.formatMoney(this.state.selectedRoom.rate,true,false) : "Loading..."}</h2>
                            <Button
                                text="Book Now"
                                className={"btn-book" + (this.state.hotelDetails === null ? " bp3-skeleton" : "")}
                                intent={Intent.PRIMARY}
                                onClick={() => this.bookRoom()}
                            />
                        </div>
                    </div>

                </Card>
            </React.Fragment>
        )
    }
}