import React from 'react';
import Marker from '../Images/png/marker.png';

export const convertToHtmlChar = (value = "") => {

    value = value.replace(/gt;/g, '>');
    value = value.replace(/Lt;/g, '<');
    value = value.replace(/lt;/g, '<');
    value = value.replace(/quot;/g, '"');
    value = value.replace(/apos;/g, "'");
    value = value.replace(/amp;/g, '&');

    return value;

}

export const fieldValidation = (value, fieldName, validationRules = []) => {

    let errors = [];
    validationRules.map((rule) => {
        var length = 0;

        rule = rule.split(',');

        let ruleName = rule[0];

        if (ruleName === "required") {
            if (validateRequired(value) === false) {
                errors.push("This field is required.");
            }
        }
        if (ruleName === "email") {
            if (validateEmail(value) === false) {
                errors.push("Please enter valid email address.");
            }
        }
        if (ruleName === "numeric") {
            if (validateNumeric(value) === false) {
                errors.push("Please enter valid number.");
            }
        }
        if (ruleName === "min") {

            length = (rule[1] !== undefined) ? rule[1] : 0;

            if (validateMinLength(value, length) === false) {
                errors.push("Minimum field length should be " + length + "");
            }
        }
        if (ruleName === "max") {

            length = (rule[1] !== undefined) ? rule[1] : 0;

            if (validateMaxLength(value, length) === false) {
                errors.push("Maximum field length should be " + length + "");
            }
        }
        if (ruleName === "length") {

            length = (rule[1] !== undefined) ? rule[1] : 0;

            if (validateLength(value, length) === false) {
                errors.push("Field length should be " + length + "");
            }
        }
        if (ruleName === "regx") {

            let regxString = (rule[1] !== undefined) ? rule[1] : '/^+$/';
            let regx = new RegExp(regxString);
            if (!regx.test(value)) {
                let message = (rule[2] !== undefined) ? rule[2] : "invalid format.";
                errors.push(message);
            }

        }
        return errors;
    });

    if (errors.length === 0) {
        return {status: true, errors: errors};
    }
    return {status: false, errors: errors};
}

export const validateRequired = (value) => {

    if (value !== undefined && value !== null && value !== '') {
        return true;
    }
    return false;
}
export const validateEmail = (value) => {

    let regx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (regx.test(value)) {
        return true;
    }
    return false;
}

export const validateNumeric = (value) => {

    if (!isNaN(value)) {
        return true;
    }
    return false;
}
export const validateLength = (value, length) => {

    if (value === null || value === undefined) {
        return false;
    }

    if (parseInt(length) === parseInt(value.length)) {
        return true;
    }
    return false;
}
export const validateMinLength = (value, length) => {
    if (value === null || value === undefined) {
        return false;
    }

    if (parseInt(length) <= parseInt(value.length)) {
        return true;
    }
    return false;
}
export const validateMaxLength = (value, length) => {
    if (value === null || value === undefined) {
        return false;
    }

    if (parseInt(length) >= parseInt(value.length)) {
        return true;
    }
    return false;
}

export const renderErrorComponent = (errors) => {

    if (Array.isArray(errors)) {
        return errors.map((error, key) => {

            return <React.Fragment key={Math.floor((Math.random() * 100000) + 1) + "" + key}><span
                className="text-danger font-13 text-capitalize">* {error}</span>
                <br/>
            </React.Fragment>
        });

    } else {
        return <span className="text-danger text-capitalize">{errors}</span>
    }

}

export const MapMarker = ({text}) => <div>{text}<img
    src={Marker} style={{height: "20px", width: "20px"}} alt={text}/>
</div>