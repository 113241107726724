import {connect} from 'react-redux';
import SearchResultList from '../../SearchResultList/index';
import {getHotelListResultsAction} from '../actions';
import {getHotelList} from "../actions/action";


const mapStateToProps = state => {

    return {
        hotelList: state.SearchResultsReducer.hotelList,
    }
}
const mapDispatchToProps = dispatch => {

    return {
        getHotelListResults: (data) => {
            return getHotelList(data)
                .then((res) => {
                    const sources = res.data;
                    if (sources.status === true) {
                        dispatch(getHotelListResultsAction(sources.data));
                    } else {
                        dispatch(getHotelListResultsAction([]));

                    }
                }).catch((error) => {
                    dispatch(getHotelListResultsAction([]));
                })
        },

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultList);
