import React from 'react';
import {Card, Button, Intent} from "@blueprintjs/core";
import {Helper} from 'gg-react-utilities';

export default class PaymentSummery extends React.Component {
    constructor(props) {
        super(props);

        this.state = this.defaultProps();
    }

    defaultProps = () => {
        return {
            searchTerms: this.props.searchTerms,
            skeleton: this.props.skeleton,
            selectedRoom: this.props.selectedRoom,
            loading: this.props.loading

        };
    }

    componentWillReceiveProps(nextProps) {

        this.setState({
            searchTerms: nextProps.searchTerms,
            selectedRoom: nextProps.selectedRoom,
            skeleton: nextProps.skeleton,
            loading: nextProps.loading,
        })
    }

    render() {
        if (this.state.skeleton === true) {
            return (
                <React.Fragment>
                    <Card interactive={false} className="col-12 p-sm-4">
                        <h4>Payment Details</h4>

                        <div className="col-12 px-0">
                            <div className="py-2">
                                <h4 className="bp3-skeleton">Booking Summary</h4>
                                <div className="payment_summery pb-3">
                                    <div className="col pr-sm-1 pl-sm-0">
                                        <h6 className={"bp3-skeleton"}>TITLE TITLE</h6>
                                    </div>
                                    <div className="col pl-sm-1 pr-sm-0 text-right">
                                        <h6 className="text-nowrap bp3-skeleton">VALUEVALUE VALUEVALUE </h6>
                                    </div>
                                </div>

                            </div>
                            <div className="clearfix"></div>
                            <hr/>

                            <div className="payment_summery">
                                <h4 className={"bp3-skeleton"}>TITLE TITLE</h4>
                                <h4 className={"bp3-skeleton"}>VALUEVALUE VALUEVALUE</h4>
                            </div>


                            <div className="d-flex justify-content-sm-end justify-content-center">
                                <Button
                                    text="Proceed to pay"
                                    className="bp3-small btn-pay bp3-skeleton"
                                    intent={Intent.PRIMARY}
                                />
                            </div>
                        </div>
                    </Card>
                </React.Fragment>
            )
        }
        return (
            <React.Fragment>
                <Card interactive={false} className="col-12 p-sm-4">
                    <h4>Payment Details</h4>

                    <div className="col-12 px-0">

                        {this.state.selectedRoom !== null ?

                            this.state.selectedRoom.rate_bifurcation.length > 1 ?
                                /*  this.state.selectedRoom.rate_bifurcation.sort(function(a, b) {
                                      return a.sort_order - b.sort_order;
                                  });*/
                                <React.Fragment>
                                    <div className="py-2">
                                        {this.state.selectedRoom.rate_bifurcation.map((rate, key) => {

                                            if (rate.code !== 'grand_total') {
                                                return <div className="payment_summery pb-3" key={key}>
                                                    <div className="col pr-sm-1 pl-sm-0">
                                                        <h6>{rate.title}</h6>
                                                    </div>
                                                    <div className="col pl-sm-1 pr-sm-0 text-right">
                                                        <h6 className="text-nowrap">{rate.type} &nbsp;{Helper.formatMoney(rate.value,true,false)}</h6>
                                                    </div>
                                                </div>
                                            }
                                            return false;
                                        })}
                                    </div>
                                    <div className="clearfix"></div>
                                    <hr/>
                                </React.Fragment>
                                : ""
                            : ""}

                        {this.state.selectedRoom !== null ?

                            this.state.selectedRoom.rate_bifurcation.map((rate, key) => {

                                if (rate.code === 'grand_total') {
                                    return <div className="payment_summery" key={key}>
                                        <h4>{rate.title}</h4>
                                        <h4>{Helper.formatMoney(rate.value,true,false)}</h4>
                                    </div>

                                }
                                return false;
                            })

                            : ""}


                        <div className="d-flex justify-content-sm-end justify-content-center">
                            <Button
                                text={(this.state.loading === false) ? "Proceed to pay" : "booking your room..."}
                                className="bp3-small btn-pay"
                                intent={Intent.PRIMARY}
                                onClick={this.props.bookRoom}
                                loading={this.state.loading}
                            />
                        </div>
                    </div>
                </Card>
            </React.Fragment>
        )
    }
}