import React from 'react';
import {Card, Elevation} from "@blueprintjs/core";
// import HotelQuality from '../HotelQuality/index';
import HotelReviewsPanel from './HotelReviews';
// import TripAdviserPanel from './TripAdviserPanel';

export default class Reviews extends React.Component {
    constructor(props) {
        super(props);

        this.state = this.defaultProps();
    }

    defaultProps = () => {
        return {
            skeleton: this.props.skeleton,
            hotelDetails: this.props.hotelDetails,
        };
    }


    componentWillReceiveProps(nextProps) {

        this.setState({
            skeleton: nextProps.skeleton,
            hotelDetails: nextProps.hotelDetails,

        })
    }

    render() {
        return (
            <React.Fragment>
                <Card interactive={false} elevation={Elevation.TWO} className="col-12">
                    <div className="card-header">
                        <h2>Reviews</h2>
                    </div>
                    <div className="card-body">
                        <div className="mt-4 reviews">
                            <HotelReviewsPanel
                                reviews={(this.state.hotelDetails !== null) ? this.state.hotelDetails.reviews : []}/>
                            {/*<Tabs
                                animate={this.state.animate}
                                id="TabsExample"
                                key={this.state.vertical ? "vertical" : "horizontal"}
                                renderActiveTabPanelOnly={this.state.activePanelOnly}
                                vertical={this.state.vertical}
                            >
                                <Tab id="search_tour" title="Search Tours" panel={<TripAdviserPanel />} />
                                <Tab id="costomised_search" title="Costomised Search" panel={<HotelReviewsPanel />} />
                            </Tabs>*/}
                        </div>
                    </div>
                </Card>
            </React.Fragment>
        )
    }
}