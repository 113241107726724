import React from 'react';
import TopBar from '../core/components/TopBar';
import Gallery from './components/Gallery';
import Overview from './components/Overview';
import ChooseRoom from './components/ChooseRoom';
import Reviews from './components/Reviews/index';
import GoogleApiWrapper from './components/Maps';
import Amenities from './components/Amenities';
import BookNow from './components/BookNow';
import {validateAndReFormatData} from '../core/Helpers/SearchUrlValidator';
import {base64_url_encode, getAllUrlParams} from "../core/components/QueryString";
import {ssoInstance, Auth} from "gg-react-utilities";
import Config from "../Config";
import Policies from "./components/Policies";
import no_property from '../core/Images/svg/no_property.svg';
import {Link} from "react-router-dom";
import {Elevation, Intent, Position, Toaster, Card} from "@blueprintjs/core/lib/esm/index";

export default class HotelDetails extends React.Component {
    constructor(props) {
        super(props);

        this.state = this.defaultProps();
    }

    defaultProps = () => {
        return {
            searchTerms: null,
            hotelDetails: null,
            skeleton: true,
            isApiException: false,
            selectedRoom: null,
        };
    }

    componentDidMount() {
        let values = getAllUrlParams(decodeURI(window.location.search));
        let data = validateAndReFormatData(values);

        this.setState({searchTerms: data}, () => {
            this.getHotelDetails(this.state.searchTerms);
        });
    }

    bookRoom = (selectedRoom) => {
        if (Auth.isLoggedIn() === false) {
            Auth.login(true);
            Toaster.create({
                position: Position.BOTTOM,
            }).show({
                message: 'one step away. Please login to Book Room.',
                intent: Intent.WARNING
            });

            return false;
        }
        this.setState({
            selectedRoom,
        }, () => {
            const formData = new FormData();

            formData.append('locationLabel', (this.state.searchTerms.locationLabel.length > 20) ? this.state.searchTerms.locationLabel.substring(0, 20) + "..." : this.state.searchTerms.locationLabel);
            formData.append('startDate', this.state.searchTerms.startDate.getTime());
            formData.append('endDate', this.state.searchTerms.endDate.getTime());
            formData.append('rooms', base64_url_encode(JSON.stringify(this.state.searchTerms.rooms)));
            formData.append('location', this.state.searchTerms.location);
            formData.append('hotel', base64_url_encode(this.state.searchTerms.hotel));
            formData.append('provider', this.state.searchTerms.provider);
            formData.append('selectedRoom', base64_url_encode(selectedRoom.room_code));

            const queryString = new URLSearchParams(formData).toString();

            window.location = process.env.REACT_APP_URL + 'booking?' + queryString;

        });

    }

    onRoomChange = (selectedRoom) => {
        this.setState({
            selectedRoom,
        });
    }

    getHotelDetails = (searchTerms) => {

        var dateFormatOptions = {year: 'numeric', month: '2-digit', day: '2-digit'};

        let data = {
            "hotel_id": searchTerms.hotel,
            "provider": searchTerms.provider,
            "checkin_date": searchTerms.startDate.toLocaleDateString('EN-IN', dateFormatOptions),
            "checkout_date": searchTerms.endDate.toLocaleDateString('EN-IN', dateFormatOptions),
            "rooms": searchTerms.rooms,
        };

        const ROOT_URL = Config.API_URL;
        const GET_SEARCH_RESULTS_API = `api/hotel-details`;
        const METHOD = 'post';
        ssoInstance({
            baseURL: `${ROOT_URL}`,
            url: `${GET_SEARCH_RESULTS_API}`,
            method: METHOD,
            data: data
        }).then(response => {
            // do you have to `parse`? I don't remember...
            if (response && response.data && response.data.data) {

                this.setState({
                    hotelDetails: response.data.data,
                    selectedRoom: response.data.data.room_details[0],
                    skeleton: false,
                }, () => {
                    this.saveHotelToLocal();
                });
            }
        }).catch(error => {
            // whoops! what now?
            this.setState({
                isApiException: true,
                skeleton: false,
            });

        });
    };

    saveHotelToLocal = () => {

        var currentSearchedItem = {
            location: this.state.searchTerms.location,
            locationLabel: this.state.searchTerms.locationLabel,
            startDate: this.state.searchTerms.startDate.getTime(),
            endDate: this.state.searchTerms.endDate.getTime(),
            hotel: base64_url_encode(this.state.searchTerms.hotel),
            hotelName: this.state.hotelDetails.hotel_name,
            provider: this.state.hotelDetails.provider,
            hotelImage: this.state.hotelDetails.images[0] !== undefined ? this.state.hotelDetails.images[0] : null,
            rooms: base64_url_encode(JSON.stringify(this.state.searchTerms.rooms)),
        };

        var recentViewed = localStorage.getItem('recentViewedHotel');

        if (recentViewed === null) {

            recentViewed = [currentSearchedItem];

        } else {
            recentViewed = JSON.parse(recentViewed);

            if (Array.isArray(recentViewed)) {

                for (let i = 0; i < recentViewed.length; i++) {
                    if (recentViewed[i].hotelName.toLowerCase() === currentSearchedItem.hotelName.toLowerCase()) {
                        return false;
                    }
                }
                recentViewed.push(currentSearchedItem)

                if (recentViewed.length > 6) {
                    recentViewed.splice(0, 1);
                }
            } else {
                recentViewed = [currentSearchedItem];
            }
        }

        localStorage.setItem('recentViewedHotel', JSON.stringify(recentViewed));
    }

    /*
    * address: "Near Baga Beach, Goa, Goa, India, 403518"
    amenities: (90) [{…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}]
    description: "Lt;b>enjoy A Luxurious Experience In A Beautiful Setting!lt;/b>lt;br>lt;br>a Classic 5-star Luxury "
    hotel_name: "Resort Rio"
    id: "eyJpdiI6Ik53dmg4MEU1dXVrS0FOYWF5enBxREE9PSIsInZhbHVlIjoiUWRYK3JXQ1pJZGVJdDhzNUx4Zm9OMTZVU29FVWlcL3hQdWFUVkhZcWh6OHV5XC9pV05mS25uT1FuZkRDb0FcL1hvUEp1SXBLdVBqdDNuVm9qMkdiQ2Q4XC9RPT0iLCJtYWMiOiIyNjkxY2EzMDQ0NmIxM2Y4MGQwY2ZjY2M4YmRhZDE4ZjE3YTVkYjllMDIzOWIyYzU3ZDI0YjIzODcyYTVkNmJjIn0="
    images: (83) ["https://imgcld.yatra.com/ytimages/image/upload/t_h…/00005448/Resort_rio_day_view_1_jwGC7C_h6Jjiz.jpg"]
    latitude: "15.5646209"
    locations: (10) [{…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}]
    longitude: "73.7591528"
    provider: "Bb"
    rating: "4.9"
    reference_no: "eyJpdiI6IkxBNEFSTmVxSXJcLzU5S0VqeWtyM0lRPT0iLCJ2YWx1ZSI6IjN6U3BucHdoSUxXUXZ3UXhYdWRJTEE9PSIsIm1hYyI6Ijc4N2EyMzZlMDVmMjQyZmVlMzI3MmUyN2EwMTgxZDA1MTgxODllNzI1ZDgzMWM4NTdlZWQxM2Q0NzA0Njg2NzYifQ=="
    room_details:[{
        amenities: []
        cancellation_policy: (2) ["No refund if you cancel this booking.", "You might be charged upto the full cost of stay (i…vice charge) if you do not check-in to the hotel."]
        description: "Royale Luxury Room"
        inclusions: (2) ["Complimentary Wi-Fi Internet", ""]
        max_adults_aloud: "3"
        max_child_aloud: "0"
        max_guest_aloud: "3"
        rate: 22385
        rate_bifurcation: (3) [{…}, {…}, {…}]
        rate_plan_code: "eyJpdiI6Ill6O"
        room_type_code: "eyJpdiI6IjZ5a"
        rooms_left: "22"
        type: "Royale Luxury Room"
    }]

*/
    render() {

        if (this.state.isApiException === true) {
            return (
                <React.Fragment>
                    <div className="container">
                        <TopBar
                            title={"property currently un available"} showBackIcon={false}/>
                    </div>
                    <section className="Hotel-Details">
                        <div className="container text-center text-muted">
                            <div className="not-found border">
                                <center>
                                    <img
                                        src={no_property}
                                        alt={"notFound"}
                                        className="notFound"
                                        style={{width: '50%'}}
                                    />
                                    <h3
                                        className="mt-xl-4 mt-lg-4 mt-md-4 mt-sm-3">The Property you are searching is
                                        currently un available <br/>
                                        please search another property
                                    </h3>
                                    <Link
                                        className="bp3-button bp3-intent-primary mt-2"
                                        to="/">
                                        Go Home
                                    </Link>
                                </center>
                            </div>
                        </div>
                    </section>
                </React.Fragment>
            )
        }
        return (
            <React.Fragment>
                <div className="container">
                    <TopBar
                        title={(this.state.hotelDetails !== null) ? this.state.hotelDetails.hotel_name : "Loading Details..."}
                        showBackIcon={false}/>
                </div>
                <section className="Hotel-Details">
                    <div className="container">
                        <div className="col-12 pb-5 p-s-2">
                            <div className="row">
                                <div className="col-xl-9 col-lg-9 col-md-8 col-sm-12 col-12 p-s-0">
                                    <div className="row Detail_parts">
                                        <Gallery skeleton={this.state.skeleton}
                                                 images={(this.state.hotelDetails !== null) ? this.state.hotelDetails.images : []}/>
                                    </div>
                                </div>
                                <div
                                    className="col-xl-3 col-lg-3 col-md-4 col-sm-8 col-12 px-s-0 pr-md-0 mt-s-4 Book_Now">
                                    <BookNow skeleton={this.state.skeleton}
                                             searchTerms={this.state.searchTerms}
                                             bookRoom={(selectedRoom) => this.bookRoom(selectedRoom)}
                                             hotelDetails={this.state.hotelDetails}
                                             selectedRoom={this.state.selectedRoom}
                                             onRoomChange={(selectedRoom) => this.onRoomChange(selectedRoom)}/>
                                </div>
                                <div className="col-xl-9 col-lg-9 col-md-8 col-sm-12 col-12">
                                    <div className="row Detail_parts mt-4">
                                        <Overview skeleton={this.state.skeleton}
                                                  description={(this.state.hotelDetails !== null) ? this.state.hotelDetails.description : ""}/>
                                    </div>
                                    <div className="row Detail_parts mt-4">
                                        <Amenities skeleton={this.state.skeleton}
                                                   amenities={(this.state.hotelDetails !== null) ? this.state.hotelDetails.amenities : []}/>
                                    </div>

                                    <div className="row Detail_parts mt-4">
                                        <ChooseRoom skeleton={this.state.skeleton}
                                                    selectedRoom={this.state.selectedRoom}
                                                    bookRoom={(selectedRoom) => this.bookRoom(selectedRoom)}
                                                    roomsDetails={(this.state.hotelDetails !== null) ? this.state.hotelDetails.room_details : []}
                                                    provider={(this.state.hotelDetails !== null) ? this.state.hotelDetails.provider : []}/>
                                    </div>
                                    {(this.state.hotelDetails !== null) ? (this.state.hotelDetails.provider.toUpperCase() === 'BB') ?
                                        <div className="row Detail_parts mt-4">
                                            <Reviews
                                                hotelDetails={this.state.hotelDetails}
                                                skeleton={this.state.skeleton}/>
                                        </div>
                                        : "" : ""}
                                    <div className="row Detail_parts mt-4">
                                        <Card interactive={false} elevation={Elevation.TWO} className="col-12">
                                            <GoogleApiWrapper skeleton={this.state.skeleton}
                                                              hotelName={(this.state.hotelDetails !== null) ? this.state.hotelDetails.hotel_name : "Loading Details..."}
                                                              nearbyLocations={(this.state.hotelDetails !== null) ? this.state.hotelDetails.locations : []}
                                                              location={(this.state.hotelDetails !== null) ? {
                                                                  latitude: this.state.hotelDetails.latitude,
                                                                  longitude: this.state.hotelDetails.longitude
                                                              } : {latitude: 0, longitude: 0}}/>
                                        </Card>
                                    </div>
                                    <div className="row Detail_parts mt-4">
                                        <Policies
                                            skeleton={this.state.skeleton}
                                            hotelDetails={(this.state.hotelDetails !== null) ? this.state.hotelDetails : null}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}