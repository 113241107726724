import React from 'react';
import {Card, Elevation} from "@blueprintjs/core";

export default class Reviews extends React.Component {

    constructor(props) {
        super(props);

        this.state = this.defaultProps();
    }

    defaultProps = () => {
        return {
            skeleton: this.props.skeleton,
            amenities: this.props.amenities,
        };
    }


    componentWillReceiveProps(nextProps) {

        this.setState({
            skeleton: nextProps.skeleton,
            amenities: nextProps.amenities,
        })
    }


    render() {
        return (
            <React.Fragment>
                <Card interactive={false} elevation={Elevation.TWO} className="col-12">
                    <div className="card-header">
                        <h2>Amenities</h2>
                    </div>
                    <div className="card-body">

                        {
                            this.state.amenities.length > 0 ?
                                <div className="row pt-1 amenities">
                                    {this.state.amenities.map((amenity, key) => {

                                        return <div className="col-md-4 col-sm-4 col-6 d-flex" key={key}>
                                            <img src={amenity.icon} alt={amenity.name}
                                                 style={{height: '25px', width: '25px'}}
                                                 className="mr-1 mt-2"/> <h6
                                            key={key}>{amenity.name}</h6>
                                        </div>

                                    })}
                                </div>
                                : ""
                        }
                    </div>
                </Card>
            </React.Fragment>
        )
    }
}