import React from 'react';
import NoHotel from '../../core/Images/svg/no_hotel.svg';

export default class Recently_Viewed extends React.Component {
    constructor() {
        super();

        this.state = {
            recentViewed: this.recentViewed().reverse()
        }
    }

    recentViewed = () => {


        let recentViewed = localStorage.getItem('recentViewedHotel');

        if (recentViewed === null) {

            recentViewed = [];

        } else {
            recentViewed = JSON.parse(recentViewed);

            if (!Array.isArray(recentViewed)) {

                recentViewed = [];
            }
        }

        return recentViewed;
    }

    redirectToHotelDetails = (selectedHotel) => {

        const formData = new FormData();

        formData.append('locationLabel', (selectedHotel.locationLabel.length > 20) ? selectedHotel.locationLabel.substring(0, 20) + "..." : selectedHotel.locationLabel);
        formData.append('startDate', selectedHotel.startDate);
        formData.append('endDate', selectedHotel.endDate);
        formData.append('rooms', selectedHotel.rooms);
        formData.append('location', selectedHotel.location);
        formData.append('hotel', selectedHotel.hotel);
        formData.append('provider', selectedHotel.provider);

        const queryString = new URLSearchParams(formData).toString();

        window.open(process.env.REACT_APP_URL + 'hotel?' + queryString, '_blank');

    }

    render() {
        return (
            <React.Fragment>
                <div className="col-12 px-sm-0">
                    <div className="pt-0 pb-4 pb-s-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <h2>Recently viewed Hotels</h2>
                        </div>
                    </div>

                    <div className="">
                        <div className="row">
                            {
                                this.state.recentViewed.map((item) => {
                                    return <div className="col-lg-3 col-md-4 col-sm-6 col-6 px-s-1">
                                        <div className="viewedbox">
                                            <a href="#!" onClick={(item1) => this.redirectToHotelDetails(item)}>
                                                <div className="viewed_img">
                                                    <img src={item.hotelImage !== null ? item.hotelImage : NoHotel}
                                                         className="img-fluid"
                                                         alt=""
                                                    />
                                                </div>
                                                <div className="viewed_detials">
                                                    <h6 className="viewed-name">{item.hotelName !== null ? item.hotelName : ""}</h6>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}