import React from 'react';
import TopBar from '../core/components/TopBar';
import SearchBar from "../SearchBar";
import Filters from "./Filters";
import HotelList from './List/HotelList';
import {getAllUrlParams} from "../core/components/QueryString";
import {validateAndReFormatData} from '../core/Helpers/SearchUrlValidator';
import {Button, Icon, Intent} from "@blueprintjs/core";

export default class SearchResultList extends React.Component {

    constructor(props) {
        super(props);

        this.state = this.defaultProps();
    }

    defaultProps = () => {

        return {
            location: null,
            locationLabel: "",
            startDate: new Date(),
            endDate: new Date(new Date(new Date().getFullYear(), (new Date().getMonth()), (new Date().getDate() + 1))),
            rooms: [],
            filterOptions: [],
            filters: {
                price: {
                    min: 1,
                    max: 99999999,
                    isPriceOpen: true,
                },
                categories: [],
                amenities: [],
                hotel_group: []
            },
            sort: {},
            hotels: [],
            skeleton: true,
            active: false
        };
    }

    componentDidMount() {

        let values = getAllUrlParams(decodeURI(window.location.search));
        let data = validateAndReFormatData(values);

        this.setState(data, () => {
            this.fetchHotelList();
        });

    }

    componentWillReceiveProps(nextProps) {

        let hotelList = nextProps.hotelList;

        let defaultFilters = this.defaultProps().filters;

        this.setState({
            filterOptions: (hotelList.filters !== undefined) ? hotelList.filters : Object.assign(defaultFilters, {isPriceOpen: false}),
            hotels: (hotelList.hotels !== undefined) ? hotelList.hotels : [],
            skeleton: false,
        })

    }


    fetchHotelList = () => {

        this.setState({
            skeleton: true
        }, () => {
            var dateFormatOptions = {year: 'numeric', month: '2-digit', day: '2-digit'};

            let data = {
                "search_id": this.state.location,
                "checkin_date": this.state.startDate.toLocaleDateString('EN-IN', dateFormatOptions),
                "checkout_date": this.state.endDate.toLocaleDateString('EN-IN', dateFormatOptions),
                "rooms": this.state.rooms,
                "filter": this.state.filters,
                "sort": this.state.sort,
            };

            this.props.getHotelListResults(data);
        });

    }

    updateFilters = (filters) => {

        this.setState({
            filters
        }, () => {
            this.fetchHotelList();
        });
    }

    updateSorting = (e) => {

        let sort = this.state.sort;

        if (e.target.value !== "" && e.target.value !== null && e.target.value !== undefined) {
            sort = {price: e.target.value};
        } else {
            sort = {};
        }
        this.setState({
            sort
        }, () => {
            this.fetchHotelList();
        });
    }

    hotel_filter() {
        this.setState({
            active: !this.state.active
        })
    }

    resetFilters = () => {
        let filters = this.defaultProps().filters;

        let defaultPriceFilter = {price: this.state.filterOptions.price};

        Object.assign(filters, this.props.defaultFilters);
        Object.assign(filters, defaultPriceFilter);

        this.setState({
            filters
        }, () => {
            this.updateFilters(this.state.filters);
        })


    }

    render() {
        var dateFormatOptions = {weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'};

        return (
            <React.Fragment>
                <div className="bg-white border-none">
                    <div className="container">
                        <TopBar
                            title={this.state.locationLabel.toUpperCase() + " | " + this.state.startDate.toLocaleDateString('en-IN', dateFormatOptions) + " - " + this.state.endDate.toLocaleDateString('en-IN', dateFormatOptions) + " | " + this.state.rooms.length + " Room"}/>
                    </div>
                </div>
                <div className="search_place">
                    <div className="container">
                        <SearchBar searchTerms={this.state}/>
                    </div>
                    <div className="container py-5">
                        <div className="col-12 px-0">
                            <div className="row">
                                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12 ">
                                    <Button
                                        className={this.state.active
                                            ? "floating-menu bp3-button bp3-small hidden-xl hidden-lg hidden-md visible-sm visible-xs active"
                                            : "floating-menu bp3-button bp3-small hidden-xl hidden-lg hidden-md visible-sm visible-xs"}
                                        intent={Intent.PRIMARY}
                                        onClick={this.hotel_filter.bind(this)}
                                    >
                                        {this.state.active ? <Icon icon="cross" iconSize={28}/> :
                                            <Icon icon="filter-list" iconSize={28}/>}
                                    </Button>
                                    <div className="hotel_filter">
                                        <Filters filterOptions={this.state.filterOptions} filters={this.state.filters}
                                                 updateFilters={(filters) => this.updateFilters(filters)}
                                                 resetFilters={() => this.resetFilters()}
                                                 defaultFilters={this.defaultProps().filters}
                                                 skeleton={this.state.skeleton}/>
                                    </div>
                                </div>

                                <div
                                    className="col-xl-9 col-lg-9 col-md-8 col-sm-12 col-12 px-s-0 px-sm-2 px-md-0 px-lg-0 px-xl-0 px-0">

                                    <div className="col-12">
                                        <div className="row justify-content-end">
                                            <div className="col-lg-5 col-md-8 col-12">
                                                <div className="form-group d-flex align-items-center">
                                                    <h3 className="sortby">Sort By</h3>
                                                    <select className="form-control w-100"
                                                            onChange={this.updateSorting}>
                                                        <option value="">Select</option>
                                                        <option value="desc">Price : High to Low</option>
                                                        <option value="asc">Price : Low to High</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <HotelList listData={this.state.hotels} skeleton={this.state.skeleton}
                                               resetFilters={() => this.resetFilters()}
                                               parentState={this.state}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>);
    }
}