import React from 'react';

export default class SingleCity extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            skeleton: true,
            isApiException: false,
            cityData: this.props.cityData
        }


    }


    setSearchByPopularCityData = () => {

        let data = {
            location: this.state.cityData.city_id,
            locationLabel: this.state.cityData.name,
        }
        this.props.setSearchByPopularCityData(data);

    }


    render() {
        return (
            <div
                className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 mb-4 mb-s-2 mt-1 px-s-1">
                <div className="box">
                    <div className="country-part"
                       onClick={this.setSearchByPopularCityData}>
                        <img src={this.state.cityData.image} className="img-fluid"
                             alt="country1" style={{width: '-webkit-fill-available'}}/>
                        <div className="overlay">
                            <h2 className="country-name">
                                {this.state.cityData.name}
                            </h2>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}