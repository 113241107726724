import React from 'react';
import {Button, Intent, Dialog, Classes} from "@blueprintjs/core";
import {Helper} from 'gg-react-utilities';
import NoHotel from '../../core/Images/svg/no_hotel.svg';

export default class SingleRoomDetail extends React.Component {

    constructor(props) {
        super(props);

        this.state = this.defaultProps();
    }

    defaultProps = () => {
        return {
            skeleton: this.props.skeleton,
            roomsDetails: this.props.roomsDetails,
            provider: this.props.provider,
            isInclusionDialogOpen: false,
            isAmenitiesDialogOpen: false,
            selectedRoom: this.props.selectedRoom,
        };
    }


    componentWillReceiveProps(nextProps) {

        this.setState({
            skeleton: nextProps.skeleton,
            roomsDetails: nextProps.roomsDetails,
            provider: nextProps.provider,
            selectedRoom: nextProps.selectedRoom,
        })
    }

    bookRoom = () => {
        this.props.bookRoom(this.state.roomsDetails);
    }

    handleInclusionDialogOpen = () => this.setState({isInclusionDialogOpen: true});
    handleInclusionDialogClose = () => this.setState({isInclusionDialogOpen: false});

    handleAmenitiesDialogOpen = () => this.setState({isAmenitiesDialogOpen: true});
    handleAmenitiesDialogClose = () => this.setState({isAmenitiesDialogOpen: false});

    render() {
        if (this.state.roomsDetails !== null && this.state.skeleton === false) {

            if (this.state.provider.toUpperCase() === 'BB') {
                return (
                    <React.Fragment>
                        <div id={this.state.roomsDetails.room_code} style={{
                            position: 'relative',
                            bottom: '35px'
                        }}>
                        </div>
                        <h3
                            className="font-weight-medium text-dark">{this.state.roomsDetails.type}</h3>
                        <div className="row pt-1">
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-4 col-5 pr-s-0 pb-s-2">
                                <div className="d-flex justify-content-center align -items-sm-center align-items-md-around">
                                    <div className="">
                                        {/*<img src={require('../images/room.jpg')} className="img-fluid hotel-img"*/}
                                        {/*alt=""/>*/}
                                        <img className="img-fluid hotel-img" src={NoHotel}
                                             alt={this.state.roomsDetails.type}/>

                                    </div>
                                    <div className="room">
                                        <h4 className="font-weight-medium text-dark mt-0">Capacity</h4>
                                        <p>Max Adult: {this.state.roomsDetails.max_adults_aloud}</p>
                                        <p>Max Child: {this.state.roomsDetails.max_child_aloud}</p>
                                        {/*<h5><a href="#!">Read more</a></h5>*/}
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-4 col-7">
                                <div className="room">
                                    <h4 className="font-weight-medium text-dark mt-0">Inclusion</h4>
                                    {this.state.roomsDetails.inclusions.map((inclusion, i) => {

                                        if (inclusion !== "" && i < 3) {
                                            return <p>{(inclusion.length > 20) ? inclusion.substring(0, 20) + '...' : inclusion}</p>;
                                        }

                                        return "";
                                    })}
                                    {(this.state.roomsDetails.inclusions.length > 3) ?
                                        <h5><a href="#!" onClick={this.handleInclusionDialogOpen}>see more
                                            inclusions</a></h5>
                                        :
                                        ""
                                    }
                                    <Dialog
                                        onClose={this.handleInclusionDialogClose}
                                        title="Inclusions"
                                        isOpen={this.state.isInclusionDialogOpen}
                                    >
                                        <div className={Classes.DIALOG_BODY}>

                                            {this.state.roomsDetails.inclusions.map((inclusion, key) => {
                                                if (inclusion !== "") {
                                                    return <p key={key}>{inclusion}</p>;
                                                }
                                                return "";
                                            })}
                                        </div>
                                    </Dialog>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-4 col-6">
                                <div className="room">
                                    <h4 className="font-weight-medium text-dark mt-0">Amenities</h4>
                                    {this.state.roomsDetails.amenities.map((amenity, i) => {

                                        if (amenity.name !== "" && i < 3) {
                                            return <p key={i}>{amenity.name}</p>;
                                        }
                                        return "";
                                    })}
                                    {(this.state.roomsDetails.amenities.length > 3) ?
                                        <h5><a href="#!" onClick={this.handleAmenitiesDialogOpen}>see more amenities</a>
                                        </h5>
                                        :
                                        ""
                                    }
                                    <Dialog
                                        onClose={this.handleAmenitiesDialogClose}
                                        title="Amenities"
                                        isOpen={this.state.isAmenitiesDialogOpen}
                                    >
                                        <div className={Classes.DIALOG_BODY}>

                                            {this.state.roomsDetails.amenities.map((amenity, i) => {

                                                if (amenity.name !== "") {
                                                    return <p key={i}>{amenity.name}</p>;
                                                }
                                                return "";
                                            })}
                                        </div>
                                    </Dialog>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12 col-6 text-right room">
                                <h2>{Helper.formatMoney(this.state.roomsDetails.rate, true, false)}</h2>
                                <Button
                                    className="bp3-button btn-book font-weight-light"
                                    intent={Intent.PRIMARY}
                                    onClick={() => this.bookRoom()}>
                                    Book
                                </Button>
                            </div>
                        </div>
                        <hr/>

                    </React.Fragment>
                )
            } else {
                return (
                    <React.Fragment>
                        <h3 id="hotel_block"
                            className="font-weight-medium text-dark">{this.state.roomsDetails.type}</h3>
                        <div className="row pt-1">
                            <div className="col-xl-4 col-lg-4 col-md-2 col-sm-2 col-12 pr-s-0">
                                <div className="d-flex justify-content-between">
                                    <div className="">
                                        {/*<img src={require('../images/room.jpg')} className="img-fluid hotel-img"*/}
                                        {/*alt=""/>*/}
                                        <img className="img-fluid hotel-img" src={NoHotel}
                                             alt={this.state.roomsDetails.type}/>

                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-5 col-sm-5 col-12">
                                <div className="room">
                                    <h4 className="font-weight-medium text-dark mt-0">Amenities</h4>
                                    {this.state.roomsDetails.amenities.map((amenity, i) => {

                                        if (amenity.name !== "" && i < 3) {
                                            return <p key={i}>{amenity.name}</p>;
                                        }
                                        return "";
                                    })}
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-5 col-sm-5 col-12">
                                <div className="room">
                                    <h4 className="font-weight-medium text-dark mt-0"> &nbsp;</h4>
                                    {this.state.roomsDetails.amenities.map((amenity, i) => {

                                        if (amenity.name !== "" && i >= 3 && i < 5) {
                                            return <p key={i}>{amenity.name}</p>;
                                        }
                                        return "";
                                    })}
                                    {(this.state.roomsDetails.amenities.length >= 5) ?
                                        <h5><a href="#!" onClick={this.handleAmenitiesDialogOpen}>see more amenities</a>
                                        </h5>
                                        :
                                        ""
                                    }
                                </div>

                                <Dialog
                                    onClose={this.handleAmenitiesDialogClose}
                                    title="Amenities"
                                    isOpen={this.state.isAmenitiesDialogOpen}
                                >
                                    <div className={Classes.DIALOG_BODY}>

                                        {this.state.roomsDetails.amenities.map((amenity, i) => {

                                            if (amenity.name !== "") {
                                                return <p key={i}>{amenity.name}</p>;
                                            }
                                            return "";
                                        })}
                                    </div>
                                </Dialog>
                            </div>
                            <div className="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 text-right room">
                                <h2>{Helper.formatMoney(this.state.roomsDetails.rate, true, false)}</h2>
                                <Button
                                    className="bp3-button btn-book font-weight-light"
                                    intent={Intent.PRIMARY}
                                    onClick={() => this.bookRoom()}>
                                    Book
                                </Button>
                            </div>
                        </div>
                        <hr/>

                    </React.Fragment>
                )
            }

        }
        return (
            <React.Fragment>
                <h3 id="hotel_block" className="font-weight-medium bp3-skeleton">Superior Room with Breakfast (Size: 146
                    sqft)</h3>
                <div className="row pt-1">
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6">
                        <div className="d-flex justify-content-between">
                            <div className="bp3-skeleton">
                                <img src={require('../images/room.jpg')} className="img-fluid hotel-img" alt=""/>
                            </div>
                            <div className="room">
                                <h4 className="font-weight-medium text-dark mt-0">Capacity</h4>
                                <p className={"bp3-skeleton"}>Max Adult: 02</p>
                                <p className={"bp3-skeleton"}>Max Child: 00</p>
                                <h5 className={"bp3-skeleton"}><a href="#!">Read more</a></h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                        <div className="room">
                            <h4 className="font-weight-medium text-dark mt-0">Inclusion</h4>
                            <p className={"bp3-skeleton"}>King Sized Bed</p>
                            <p className={"bp3-skeleton"}>King Sized Bed</p>
                            <h5 className={"bp3-skeleton"}><a href="#!">Read more</a></h5>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                        <div className="room">
                            <h4 className="font-weight-medium text-dark mt-0">Amenities</h4>
                            <p className={"bp3-skeleton"}>Breakfast</p>
                            <p className={"bp3-skeleton"}>Wifi</p>
                            <h5 className={"bp3-skeleton"}><a href="#!">Read more</a></h5>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6 col-6 text-right room">
                        <h2 className={"bp3-skeleton"}>&#8377; 2739</h2>
                        <a
                            href="#hotel_block"
                            className="bp3-button btn-book font-weight-light bp3-skeleton"
                            intent={Intent.PRIMARY}
                        >
                            Book
                        </a>
                    </div>
                </div>
                <hr/>
            </React.Fragment>
        )
    }
}