import React from 'react';
import {Icon} from "@blueprintjs/core";
import {withRouter} from 'react-router-dom';

class TopBar extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showBackIcon: this.props.showBackIcon !== undefined ? this.props.showBackIcon : true
        };
    }

    componsntDidMount(){

    }

    goBack = () => {
        window.history.back();
    }

    render() {
        return (
            <div className="top-bar">
                <div className="col-12">
                    <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-start">
                            <div className="row py-3">
                                <ul className="bp3-breadcrumbs mb-breadcrumbs">
                                    {(this.state.showBackIcon === true) ?
                                        <li>
                                        <span className=""
                                              onClick={this.goBack}>
                                            <Icon icon="chevron-left" iconSize={23} className="text-dark"/>
                                        </span>
                                        </li>
                                        : ""
                                    }
                                    <li>
                                        <span className="bp3-breadcrumbs bold title">{this.props.title}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(TopBar);