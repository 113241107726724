import React  from 'react';
import { Checkbox } from "@blueprintjs/core";
import {Alignment} from "@blueprintjs/core/lib/esm/index";

export default class Category extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            selectedCategories: this.props.selectedCategories,
            category: this.props.category,
            isChecked: false
        };
    }

    componentDidMont() {
        this.state.selectedCategories.map(function (category) {

            if (category === this.state.category) {
                this.setState({
                    isChecked: true
                })
            } else {
                this.setState({
                    isChecked: false
                })
            }
            return false;
        });
    }

    componentWillReceiveProps(nextProps) {

        this.setState({
            selectedCategories: nextProps.selectedCategories,
            category: nextProps.category,
        }, () => {
            if (this.state.selectedCategories.length === 0) {
                this.setState({
                    isChecked: false
                })
            }
        });

    }

    handleChange = () => {

        this.setState({
            isChecked: !this.state.isChecked
        }, () => {
            this.updateCategory();
        })
    }

    updateCategory = () => {

        let selectedCategories = [];

        if (this.state.isChecked === true) {
            if (this.state.selectedCategories.indexOf(this.state.category) !== -1) {
                selectedCategories = this.state.selectedCategories;
            } else {
                selectedCategories = this.state.selectedCategories;
                selectedCategories.push(this.state.category)
            }
        } else {
            if (this.state.selectedCategories.indexOf(this.state.category) !== -1) {
                 this.state.selectedCategories.map((category) => {
                    if (category !== this.state.category) {
                        selectedCategories.push(category);
                    }
                    return true;
                });

            } else {
                selectedCategories = this.state.selectedCategories;
            }
        }

        this.props.updateCategories(selectedCategories);


    }



    render(){
        return(
            <li>
                <div className="d-flex align-items-baseline">
                    <div className="col-md-12 col-12 pl-1 pr-0">
                        <Checkbox
                            alignment={Alignment.RIGHT}
                            label={(this.props.category.length>25)?this.props.category.substring(0, 25) + "...":this.props.category}
                            checked={this.state.isChecked}
                            onChange={() => this.handleChange()}
                        />
                    </div>
                </div>
            </li>
        )
    }
}