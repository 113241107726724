import React from 'react';
import {Checkbox} from "@blueprintjs/core";
import {Alignment} from "@blueprintjs/core/lib/esm/index";

export default class HotelGroup extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedHotelGroups: this.props.selectedHotelGroups,
            hotelGroup: this.props.hotelGroup,
            isChecked: false
        };
    }

    componentDidMont() {
        this.state.selectedHotelGroups.map(function (hotelGroup) {

            if (hotelGroup === this.state.hotelGroup) {
                this.setState({
                    isChecked: true
                })
            } else {
                this.setState({
                    isChecked: false
                })
            }
            return false;
        });
    }


    componentWillReceiveProps(nextProps) {

        this.setState({
            selectedHotelGroups: nextProps.selectedHotelGroups,
            hotelGroup: nextProps.hotelGroup,
        }, () => {
            if (this.state.selectedHotelGroups.length === 0) {
                this.setState({
                    isChecked: false
                })
            }
        });

    }

    handleChange = () => {

        this.setState({
            isChecked: !this.state.isChecked
        }, () => {
            this.updateHotelGroup();
        })
    }

    updateHotelGroup = () => {

        let selectedHotelGroups = [];

        if (this.state.isChecked === true) {
            if (this.state.selectedHotelGroups.indexOf(this.state.hotelGroup) !== -1) {
                selectedHotelGroups = this.state.selectedHotelGroups;
            } else {
                selectedHotelGroups = this.state.selectedHotelGroups;
                selectedHotelGroups.push(this.state.hotelGroup)
            }
        } else {
            if (this.state.selectedHotelGroups.indexOf(this.state.hotelGroup) !== -1) {
                this.state.selectedHotelGroups.map((hotelGroup) => {
                    if (hotelGroup !== this.state.hotelGroup) {
                        return selectedHotelGroups.push(hotelGroup);
                    }
                    return true;
                });

            } else {
                selectedHotelGroups = this.state.selectedHotelGroups;
            }
        }

        this.props.updateHotelGroups(selectedHotelGroups);


    }


    render() {
        return (
            <li>
                <div className="d-flex align-items-baseline">
                    <div className="col-md-12 col-12 pl-1 pr-0">
                        <Checkbox
                            alignment={Alignment.RIGHT}
                            label={this.props.hotelGroup}
                            checked={this.state.isChecked}
                            onChange={() => this.handleChange()}
                        />
                    </div>
                </div>
            </li>
        )
    }
}