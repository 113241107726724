import React from 'react';
import {Card} from "@blueprintjs/core";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import NoHotel from '../../core/Images/svg/no_hotel.svg';

export default class Gallery extends React.Component {

    constructor(props) {
        super(props);

        this.state = this.defaultProps();
    }

    defaultProps = () => {

        return {
            skeleton: this.props.skeleton,
            images: [],
        };
    }


    componentWillReceiveProps(nextProps) {

        if (nextProps.images.length > 0) {
            this.setState({
                skeleton: nextProps.skeleton,
                images: nextProps.images,

            })
        }
    }

    setSliders = () => {

        let slider = [];
        this.state.images.map((image) => {
            let singleSlide = Object.assign({
                original: image,
                thumbnail: image,
            });
            slider.push(singleSlide);

            return true;
        });
        if (slider.length === 0) {
            let singleSlide = Object.assign({
                original: NoHotel,
                thumbnail: NoHotel,
            });
            slider.push(singleSlide)
        }
        return slider;
    }

    render() {

        if (this.state.skeleton === true) {
            return (
                <React.Fragment>
                    <Card interactive={false} className="col-12 shadow-none border-theme rounded-0 p-s-0">
                        <div
                            className={"card-body pt-4 pb-2 p-s-0 bp3-skeleton"} style={{maxHeight: "500px"}}>
                            <ImageGallery
                                autoPlay
                                Swipe={true}
                                items={this.setSliders()}
                                lazyLoad={true}
                                showIndex={true}
                                // showBullets={true}
                                slideInterval="5000"
                                useKeyboardArrows={true}
                                className="thumbnails-horizontal"
                            />
                        </div>
                    </Card>
                </React.Fragment>
            )
        }
        return (
            <React.Fragment>
                <Card interactive={false} className="col-12 shadow-none border-theme rounded-0 p-s-0">
                    <div
                        className={"card-body pt-4 pb-2 p-s-0"}>
                        <ImageGallery
                            autoPlay
                            Swipe={true}
                            items={this.setSliders()}
                            lazyLoad={true}
                            showIndex={true}
                            onImageError={ (event)=> {
                                event.target.src = NoHotel ;
                            }}
                            onThumbnailError={ (event)=> {
                                event.target.src = NoHotel ;
                            }}
                            // showBullets={true}
                            slideInterval="5000"
                            useKeyboardArrows={true}
                            className="thumbnails-horizontal"
                        />
                    </div>
                </Card>
            </React.Fragment>
        )
    }
}