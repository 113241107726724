import React from 'react'
import {Route} from 'react-router-dom'
import {Header, Footer} from 'gg-react-utilities';

const PrivateRoute = ({component: Component, ...rest}) => (

    <Route {...rest} render={(props) => (
        <div className="Hotel">
            {((rest.disableLoginIcon !== undefined) ? <Header disableLoginIcon/> : <Header/>)}
            <Component {...props} />
            <Footer/>
        </div>
    )}/>
);

export default PrivateRoute;
