import React from 'react';
import NoHotel from '../../core/Images/svg/no_hotel.svg';
import {base64_url_encode} from "../../core/components/QueryString";
import ReactStars from 'react-stars'
import {Button, Intent} from "@blueprintjs/core";

export default class HotelDetails extends React.Component {
    constructor(props) {
        super(props);

        this.state = this.defaultProps();
    }

    defaultProps = () => {
        return {
            searchTerms: this.props.searchTerms,
            hotelDetails: this.props.hotelDetails,
            skeleton: this.props.skeleton,
            selectedRoom: this.props.selectedRoom,
        };
    }

    componentWillReceiveProps(nextProps) {

        this.setState({
            searchTerms: nextProps.searchTerms,
            hotelDetails: nextProps.hotelDetails,
            selectedRoom: nextProps.selectedRoom,
            skeleton: nextProps.skeleton,
        })
    }

    redirectToHotelDetails = () => {

        const formData = new FormData();

        formData.append('locationLabel', (this.state.searchTerms.locationLabel.length > 20) ? this.state.searchTerms.locationLabel.substring(0, 20) + "..." : this.state.searchTerms.locationLabel);
        formData.append('startDate', this.state.searchTerms.startDate.getTime());
        formData.append('endDate', this.state.searchTerms.endDate.getTime());
        formData.append('rooms', base64_url_encode(JSON.stringify(this.state.searchTerms.rooms)));
        formData.append('location', this.state.searchTerms.location);
        formData.append('hotel', base64_url_encode(this.state.hotelDetails.id));
        formData.append('provider', this.state.hotelDetails.provider);

        const queryString = new URLSearchParams(formData).toString();

        window.location = (process.env.REACT_APP_URL + 'hotel?' + queryString);

    }

    onImageError = (e) => {
        this.setState({
            hotelDetails: Object.assign(this.state.hotelDetails, {images: [NoHotel]})
        });
    }

    render() {
        var dateFormatOptions = {weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'};

        if (this.state.skeleton === true) {
            return (
                <React.Fragment>
                    <div className="col-12 px-s-0">
                        <h2>Booking Summary</h2>
                        <div className="row mt-4">
                            <div className="col-md-4 col-sm-12 col-12 ">
                                <div className={"bp3-skeleton w-100 h-100"}></div>
                            </div>
                            <div className="col-md-8 col-sm-12 col-12 pl-0 px-s-3">
                                <div className="">
                                    <h3 className={"bp3-skeleton"}>
                                        Superior Room with Room with
                                    </h3>
                                    <h5 className={"bp3-skeleton"}>
                                        Superior Room with Room with
                                    </h5>
                                </div>
                                <div className="d-flex align-items-center mt-2">
                                    <div className="d-flex align-items-center bp3-skeleton">
                                    <span className="viewed-rating">
                                    N/A
                                    </span>
                                        <ReactStars
                                            value={0}
                                            count={5}
                                            size={24}
                                            edit={false}
                                            color2={'#ffd700'}
                                        />
                                    </div>
                                    {/*<h6 className="viewed-review ml-3"><img src={require('../images/trip.png')} className="img-fluid mr-1" alt="" /> 3.5/5  Very Good</h6>*/}
                                </div>
                                <div className="d-flex align-items-center mt-2 checkin bp3-skeleton">
                                    <h5>Check In:</h5>
                                    <h6 className="text-nowrap ">
                                        23/12/2019 12:44 PM
                                    </h6>
                                </div>
                                <div className="d-flex align-items-center checkin bp3-skeleton">
                                    <h5>Check Out:</h5>
                                    <h6 className="text-nowrap">
                                        23/12/2019 12:44 PM
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                        <hr/>

                        <div className="payment_summery py-2 d-flex align-items-center justify-content-between">
                            <h5 className="mb-0 bp3-skeleton">Room Details:
                                <span className="font-weight-normal ml-2 ">
                                    Superior Room with Room with
                                </span>
                            </h5>
                            <Button
                                text="Edit"
                                intent={Intent.PRIMARY}
                                className="bp3-small px-3 bp3-skeleton" style={{cursor: "pointer"}}
                            />
                        </div>
                        <div className="clearfix"></div>
                        <hr/>

                        <div>
                            <h5 className="font-16 bp3-skeleton">Inclusions: </h5>
                            <ul>
                                <li className={'bp3-skeleton'}>
                                    Deluxe Room Only - Non Refundable -Complimentary Wi-Fi Internet
                                </li>
                                <li className={'bp3-skeleton'}>
                                    No Check Out Allowed on 24th December
                                </li>
                                <li className={'bp3-skeleton'}>
                                    No Check Out Allowed on 24th Dec & 31st Dec.
                                </li>
                            </ul>
                        </div>
                        <div className="clearfix"></div>
                        <hr/>

                        <div>
                            <h5 className="font-16 bp3-skeleton">Booking & Cancellation Policy: </h5>
                            <ul>
                                <li className={'bp3-skeleton'}>
                                    No refund if you cancel this booking.
                                </li>
                                <li className={'bp3-skeleton'}>
                                    You might be charged upto the full cost of stay (including taxes && service charge)
                                    if you do not check-in to the hotel.
                                </li>
                            </ul>
                        </div>
                    </div>
                </React.Fragment>
            )
        }
        return (
            <React.Fragment>
                <div className="col-12 px-s-0">
                    <h2>Booking Summary</h2>
                    <div className="row mt-4">
                        <div className="col-md-4 col-sm-12 col-12">
                            <img src={(this.state.hotelDetails) ? this.state.hotelDetails.images[0] : NoHotel}
                                 className={"img-fluid hotel-img"} onError={this.onImageError} alt=""/>
                        </div>
                        <div className="col-md-8 col-sm-12 col-12 pl-0 px-s-3">
                            <div className="">
                                <h3 className={"" + (this.state.skeleton ? "bp3-skeleton" : "") + ""}>
                                    {(this.state.hotelDetails !== null) ? this.state.hotelDetails.hotel_name : "Superior Room with "}
                                </h3>
                                <h5 className={"" + (this.state.skeleton ? "bp3-skeleton" : "") + "font-weight-normal text-muted"}>
                                    {(this.state.hotelDetails !== null) ? this.state.hotelDetails.address : "Superior Room with "}
                                </h5>
                            </div>
                            <div className="d-flex align-items-center mt-2">
                                <div className="d-flex align-items-center">
                                    <span
                                        className="viewed-rating">{(this.state.hotelDetails) ? parseFloat(this.state.hotelDetails.rating).toFixed(1) : "N/A"}</span>
                                    <ReactStars
                                        value={(this.state.hotelDetails) ? parseFloat(this.state.hotelDetails.rating).toFixed(1) : 0}
                                        count={5}
                                        size={20}
                                        edit={false}
                                        className="stars"
                                        color2={'#F5A623'}
                                    />
                                </div>

                                <h6 className="place-days">
                                    {(this.state.trip_advisor_rating !== undefined && this.state.trip_advisor_rating !== null && this.state.trip_advisor_rating !== '') ? this.state.trip_advisor_rating + " / " : ""}
                                    {(this.state.trip_advisor_total_review !== undefined && this.state.trip_advisor_total_review !== null && this.state.trip_advisor_total_review !== '') ? this.state.trip_advisor_total_review : ""}
                                    {
                                        (this.state.trip_advisor_rating_image_url !== undefined && this.state.trip_advisor_rating_image_url !== null && this.state.trip_advisor_rating_image_url !== '')
                                            ?
                                            <img src={this.state.trip_advisor_rating_image_url}
                                                 alt={this.state.trip_advisor_rating + " Rating"}
                                                 style={{height: '15px', width: '100px'}}
                                                 className={"ml-2"}
                                            />
                                            : ""
                                    }
                                </h6>

                                {/*<h6 className="viewed-review ml-3"><img src={require('../images/trip.png')} className="img-fluid mr-1" alt="" /> 3.5/5  Very Good</h6>*/}
                            </div>
                            <div className="d-flex align-items-center mt-2 checkin">
                                <h5>Check In:</h5>
                                <h6 className="text-nowrap"> {(this.state.searchTerms !== null) ? this.state.searchTerms.startDate.toLocaleDateString("en-IN", dateFormatOptions) + " " + this.state.hotelDetails.checkin_time : ""} </h6>
                            </div>
                            <div className="d-flex align-items-center checkin">
                                <h5>Check Out:</h5>
                                <h6 className="text-nowrap"> {(this.state.searchTerms !== null) ? this.state.searchTerms.endDate.toLocaleDateString("en-IN", dateFormatOptions) + "  " + this.state.hotelDetails.checkout_time : ""} </h6>
                            </div>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                    <hr/>

                    <div className="payment_summery py-2 d-flex align-items-center justify-content-between">
                        <h5 className="mb-0">Room Details:
                            <span className="font-weight-normal ml-2">
                                {(this.state.searchTerms !== null) ? this.state.searchTerms.guestCount.total + " Guest , " + this.state.searchTerms.rooms.length.toString() + " " + (this.state.selectedRoom !== null ? this.state.selectedRoom.type : " Room") + " , " + this.state.searchTerms.totalNights + " Night" : ""}
                            </span>
                        </h5>
                        <Button
                            text="Edit"
                            intent={Intent.PRIMARY}
                            className="bp3-small px-3" style={{cursor: "pointer"}}
                            onClick={this.redirectToHotelDetails}
                        />
                    </div>
                    <div className="clearfix"></div>
                    <hr/>

                    <div>
                        <h5 className="font-16">Inclusions: </h5>
                        <ul>
                            {this.state.selectedRoom.inclusions.map((inclusion) => {
                                return (inclusion === '' || inclusion === null) ? "" : <li>
                                    {inclusion}
                                </li>
                            })}
                        </ul>
                    </div>
                    <div className="clearfix"></div>
                    <hr/>

                    <div>
                        <h5 className="font-16">Booking & Cancellation Policy: </h5>
                        <ul>
                            {this.state.selectedRoom.cancellation_policy.map((policy) => {
                                return <li>
                                    {policy}
                                </li>
                            })}
                        </ul>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}