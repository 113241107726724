import {ssoInstance} from 'gg-react-utilities';
import Config from "../../Config";


export const getHotelList = (data) => {


    const ROOT_URL = Config.API_URL;
    const GET_SEARCH_RESULTS_API = `api/hotel-list`;
    const METHOD = 'post';
    return ssoInstance({
        baseURL: `${ROOT_URL}`,
        url: `${GET_SEARCH_RESULTS_API}`,
        method: METHOD,
        data: data
    });

}