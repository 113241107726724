import React from 'react';
import {Checkbox,Alignment} from "@blueprintjs/core";

export default class Amenity extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedAmenities: this.props.selectedAmenities,
            amenity: this.props.amenity,
            isChecked: false
        };
    }

    componentDidMont() {
        this.state.selectedAmenities.map(function (amenity) {

            if (amenity === this.state.amenity) {
                this.setState({
                    isChecked: true
                })
            } else {
                this.setState({
                    isChecked: false
                })
            }
            return false;
        });
    }

    componentWillReceiveProps(nextProps) {

        this.setState({
            selectedAmenities: nextProps.selectedAmenities,
            amenity: nextProps.amenity,
        }, () => {
            if (this.state.selectedAmenities.length === 0) {
                this.setState({
                    isChecked: false
                })
            }
        });

    }

    handleChange = () => {

        this.setState({
            isChecked: !this.state.isChecked
        }, () => {
            this.updateAmenity();
        })
    }

    updateAmenity = () => {

        let selectedAmenities = [];

        if (this.state.isChecked === true) {
            if (this.state.selectedAmenities.indexOf(this.state.amenity.id) !== -1) {
                selectedAmenities = this.state.selectedAmenities;
            } else {
                selectedAmenities = this.state.selectedAmenities;
                selectedAmenities.push(this.state.amenity.id)
            }
        } else {
            if (this.state.selectedAmenities.indexOf(this.state.amenity.id) !== -1) {
                this.state.selectedAmenities.map((amenity) => {
                    if (amenity !== this.state.amenity.id) {
                        return selectedAmenities.push(amenity);
                    }
                    return true;
                });

            } else {
                selectedAmenities = this.state.selectedAmenities;
            }
        }

        this.props.updateAmenities(selectedAmenities);


    }

    render() {
        return (
            <li>
                <div className="d-flex align-items-baseline">
                    <div className="col-md-12 col-12 pl-1 pr-0">
                        <Checkbox
                            alignment={Alignment.RIGHT}
                            label={(this.props.amenity.name.length>25)?this.props.amenity.name.substring(0, 25) + "...":this.props.amenity.name}
                            checked={this.state.isChecked}
                            onChange={() => this.handleChange()}
                        />
                    </div>
                </div>
            </li>
        )
    }
}