const config = {

    APP_URL: process.env.REACT_APP_URL,
    API_URL: process.env.REACT_APP_API_URL,

    ACCOUNT_API_URL: 'https://beta-accounts.globalgarner.in/',
    IS_MAINTENANCE_MODE: (process.env.REACT_APP_IS_MAINTENANCE_MODE === 'true') ? true : false,
};

export const packageConfigure = {
    APP_URL: "https://beta-dashboard.globalgarnaer.in/",
    ACCOUNT_API_URL: 'https://beta-accounts.globalgarner.in/',
    DASHBOARD_URL: 'https://beta-dashboard.globalgarnaer.in/',
    //USER_PROFILE_URL:'https://beta-dashboard.globalgarnaer.in/profile',
    STORE_API_URL: 'https://beta-store-api.globalgarner.com/v1.0.3/',
    MEGABRAND_API_URL: 'https://beta-mega-brands-api.globalgarner.in/user/v1.0.3/',
    APP_ENV: 'local',
    Extension: '.in'
}

export default config;
