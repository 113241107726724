import React from 'react';
import TopBar from "../core/components/TopBar";
import GSTDetails from './Components/GSTDetails';
import PassengerDetails from './Components/PassengerDetails';
import ContactDetails from './Components/ContactDetails';
import PaymentSummery from './Components/PaymentSummery';
import {validateAndReFormatData} from "../core/Helpers/SearchUrlValidator";
import {getAllUrlParams} from "../core/components/QueryString";
import {ssoInstance} from "gg-react-utilities";
import Config from "../Config";
import {Card} from "@blueprintjs/core";
import HotelDetails from './Components/HotelDetails';
import {fieldValidation} from "../core/Helpers/helper";
import {Intent, Position, Toaster} from "@blueprintjs/core";
import no_property from '../core/Images/svg/no_property.svg';

var dateFormatOptions = {year: 'numeric', month: '2-digit', day: '2-digit'};

export default class Booking extends React.Component {

    constructor(props) {
        super(props);

        this.state = this.defaultProps();
    }

    defaultProps = () => {
        return {
            searchTerms: null,
            hotelDetails: null,
            skeleton: true,
            loading: false,
            selectedRoom: null,
            contactDetails: {
                email: null,
                mobile: null,
                phone_country_code: null,
            },
            gstDetails: {
                isGstDetailsFilled: false,
                gst_no: null,
                name: null,
                email: null,
                address: null,
                city: null,
                pincode: null,
                state: null,
                contact_no: null
            },
            errors: {
                gstDetails: {}
            },
            providerServiceError: false
        };
    }

    componentDidMount() {
        let values = getAllUrlParams(decodeURI(window.location.search));
        let data = validateAndReFormatData(values);

        this.setState({searchTerms: data}, () => {
            this.getHotelDetails(this.state.searchTerms);
        });
    }

    validateContactDetails = () => {


        let email = this.state.contactDetails.email;
        let phone_country_code = this.state.contactDetails.phone_country_code;
        let mobile = this.state.contactDetails.mobile;

        let emailValidate = fieldValidation(email, 'email', ['required', 'email']);
        let phoneCountryCodeValidate = fieldValidation(phone_country_code, 'phone_country_code', ['required', 'min,2', 'max,4']);
        let mobileValidate = fieldValidation(mobile, 'mobile', ['required', 'numeric', 'length,10']);

        let errors = this.state.errors;

        Object.assign(errors, {
            email: emailValidate.errors,
            phone_country_code: phoneCountryCodeValidate.errors,
            mobile: mobileValidate.errors
        });
        this.setState({errors});

        if (emailValidate.status === true && phoneCountryCodeValidate.status === true && mobileValidate.status === true) {
            return true;
        }
        return false;
    };

    validateGstDetails = () => {

        let address = this.state.gstDetails.address;
        let city = this.state.gstDetails.city;
        let contact_no = this.state.gstDetails.contact_no;
        let email = this.state.gstDetails.email;
        let gst_no = this.state.gstDetails.gst_no;
        let name = this.state.gstDetails.name;
        let pincode = this.state.gstDetails.pincode;
        let state = this.state.gstDetails.state;

        let addressValidate = fieldValidation(address, 'address', ['required']);
        let cityValidate = fieldValidation(city, 'city', ['required', 'min,2', 'max,4']);
        let contactNoValidate = fieldValidation(contact_no, 'contact_no', ['required', 'numeric', 'length,10']);
        let emailValidate = fieldValidation(email, 'email', ['required', 'email']);
        let gstNoValidate = fieldValidation(gst_no, 'gst_no', ['required']);
        let nameValidate = fieldValidation(name, 'name', ['required']);
        let pincodeValidate = fieldValidation(pincode, 'pincode', ['required', 'numeric', 'length,6']);
        let stateValidate = fieldValidation(state, 'state', ['required']);

        let errors = this.state.errors;

        Object.assign(errors, {
            gstDetails: {
                address: addressValidate.errors,
                city: cityValidate.errors,
                contact_no: contactNoValidate.errors,
                email: emailValidate.errors,
                gst_no: gstNoValidate.errors,
                name: nameValidate.errors,
                pincode: pincodeValidate.errors,
                state: stateValidate.errors,
            }
        });
        this.setState({errors});

        if (
            addressValidate.status === true &&
            cityValidate.status === true &&
            contactNoValidate.status === true &&
            emailValidate.status === true &&
            gstNoValidate.status === true &&
            nameValidate.status === true &&
            pincodeValidate.status === true &&
            pincodeValidate.status === true &&
            stateValidate.status === true
        ) {
            return true;
        }
        return false;
    };

    validatePassengerDetails = () => {


        let rooms = this.state.searchTerms.rooms;

        let isValidPassengerDetails = true;

        let errors = this.state.errors;

        rooms.map((room, roomIndex) => {

            let totalPassengers = parseInt(room.adults) + parseInt(room.children);
            for (var passengerIndex = 0; passengerIndex < totalPassengers; passengerIndex++) {
                let singleRoomErrors = {};
                if (room['guest_details'] === undefined) {
                    singleRoomErrors = {
                        title: ['this field is required'],
                        first_name: ['this field is required'],
                        last_name: ['this field is required']
                    };

                    isValidPassengerDetails = false;


                } else if (room['guest_details'][passengerIndex] === undefined) {

                    singleRoomErrors = {
                        title: ['this field is required'],
                        first_name: ['this field is required'],
                        last_name: ['this field is required']
                    };

                    isValidPassengerDetails = false;

                } else {

                    let title = room['guest_details'][passengerIndex]['title'];
                    let first_name = room['guest_details'][passengerIndex]['first_name'];
                    let last_name = room['guest_details'][passengerIndex]['last_name'];

                    let titleValidate = fieldValidation(title, 'title', ['required']);
                    let firstNameValidate = fieldValidation(first_name, 'first_name', ['required', 'min,3']);
                    let lastNameValidate = fieldValidation(last_name, 'last_name', ['required', 'min,3']);

                    singleRoomErrors = {
                        title: titleValidate.errors,
                        first_name: firstNameValidate.errors,
                        last_name: lastNameValidate.errors
                    };


                    if (titleValidate.status === false || firstNameValidate.status === false || lastNameValidate.status === false) {
                        isValidPassengerDetails = false;
                    }
                }

                if (errors[roomIndex] !== undefined) {

                    if (errors[roomIndex][passengerIndex] !== undefined) {
                        Object.assign(errors[roomIndex][passengerIndex], singleRoomErrors);
                    } else {
                        Object.assign(errors[roomIndex], {[passengerIndex]: singleRoomErrors});
                    }
                } else {
                    Object.assign(errors, {[roomIndex]: {[passengerIndex]: singleRoomErrors}});
                }

            }
            return true;
        });
        this.setState({errors});

        return isValidPassengerDetails;
    };

    validateProvisionalBookingData = () => {

        let validateContactDetails = this.validateContactDetails();
        let validatePassengerDetails = this.validatePassengerDetails();
        let validateGstDetails = true;
        if (this.state.gstDetails.isGstDetailsFilled === true) {
            validateGstDetails = this.validateGstDetails();
        }
        if (validateContactDetails === true && validatePassengerDetails === true && validateGstDetails === true) {
            return true;
        }

        return false;
    };

    bookRoom = () => {

        if (this.validateProvisionalBookingData() === true) {
            let data = {
                "hotel_id": this.state.searchTerms.hotel,
                "provider": this.state.searchTerms.provider,
                "platform": "WEB",
                "room_code": this.state.searchTerms.selectedRoom,
                "rooms": this.state.searchTerms.rooms,
                "checkin_date": this.state.searchTerms.startDate.toLocaleDateString('EN-IN', dateFormatOptions),
                "checkout_date": this.state.searchTerms.endDate.toLocaleDateString('EN-IN', dateFormatOptions),
                "special_request": "no smoking area",
                "email": this.state.contactDetails.email,
                "phone_country_code": this.state.contactDetails.phone_country_code,
                "phone": this.state.contactDetails.mobile,
            };

            if (this.state.gstDetails.isGstDetailsFilled) {
                Object.assign(data, {gst_details: this.state.gstDetails});
            }

            this.setState({loading: true}, () => {
                this.provisionalBooking(data);
            });
        }

    };

    provisionalBooking = (request) => {

        const ROOT_URL = Config.API_URL;
        const GET_SEARCH_RESULTS_API = `api/booking/provisional`;
        const METHOD = 'post';
        ssoInstance({
            baseURL: `${ROOT_URL}`,
            url: `${GET_SEARCH_RESULTS_API}`,
            method: METHOD,
            data: request
        }).then(response => {
            // do you have to `parse`? I don't remember...
            if (response && response.data && response.data.data) {


                const responseData = response.data;

                if (responseData.status === true) {

                    const PayUBiz = responseData.data.pg;
                    let form = document.createElement("form");
                    form.setAttribute("method", "POST");
                    form.setAttribute("action", PayUBiz.url);
                    for (let key in PayUBiz) {
                        let hiddenField = document.createElement("input");
                        hiddenField.setAttribute("type", "hidden");
                        hiddenField.setAttribute("name", key);
                        hiddenField.setAttribute("value", PayUBiz[key]);
                        form.appendChild(hiddenField);
                    }
                    document.body.appendChild(form);
                    form.submit();
                } else {
                    this.setState({loading: false}, () => {
                        Toaster.create({position: Position.TOP,}).show({
                            message: responseData.message,
                            intent: Intent.DANGER
                        });
                    });

                }

            }
        }).catch(error => {
            if (error.response.data.error instanceof Object) {
                for (var key in error.response.data.error) {
                    Toaster.create({
                        position: Position.TOP,
                    }).show({
                        message: error.response.data.error[key][0],
                        intent: Intent.DANGER
                    });
                }
            }

        }).then(() => {
            this.setState({
                loading: false
            });
        });
    }

    getHotelDetails = (searchTerms) => {


        let data = {
            "hotel_id": searchTerms.hotel,
            "provider": searchTerms.provider,
            "checkin_date": searchTerms.startDate.toLocaleDateString('EN-IN', dateFormatOptions),
            "checkout_date": searchTerms.endDate.toLocaleDateString('EN-IN', dateFormatOptions),
            "rooms": searchTerms.rooms,
            "selected_room": searchTerms.selectedRoom,
        };

        const ROOT_URL = Config.API_URL;
        const GET_SEARCH_RESULTS_API = `api/hotel-details`;
        const METHOD = 'post';
        ssoInstance({
            baseURL: `${ROOT_URL}`,
            url: `${GET_SEARCH_RESULTS_API}`,
            method: METHOD,
            data: data
        }).then(response => {
            // do you have to `parse`? I don't remember...
            if (response && response.data && response.data.data) {

                this.setState({
                    hotelDetails: response.data.data,
                    selectedRoom: response.data.data.room_details[0],
                    skeleton: false,
                });
            }
        }).catch(error => {
            this.setState({
                providerServiceError: true
            });

        });
    };

    render() {

        if (this.state.providerServiceError === true) {
            return (
                <div className="container">
                    <TopBar
                        title={"Hotel Provider Service unavailable"} showBackIcon={false}/>
                    <section className="Booking-Details pb-5">
                        <Card>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 px-0">
                                        <div className="text-center text-muted">
                                            <div className="not-found">
                                                <center>
                                                    <img
                                                        src={no_property}
                                                        alt={"notFound"}
                                                        className="notFound"
                                                        style={{width: '50%'}}
                                                    />
                                                    <h3
                                                        className="mt-xl-4 mt-lg-4 mt-md-4 mt-sm-3">
                                                        <b>
                                                            Hotel Provider Service unavailable.
                                                        </b></h3><a
                                                    className="bp3-button bp3-intent-primary mt-2"
                                                    href={process.env.REACT_APP_URL}>Go Home</a>
                                                </center>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </section>
                </div>
            )
        }
        return (
            <React.Fragment>
                <div className="container">
                    <TopBar
                        title={this.state.skeleton === true ? "Loading your booking details..." : "Review Your Booking"}
                        showBackIcon={false}/>
                </div>
                <section className="Booking-Details">
                    <div className="container">
                        <div className="col-12 pb-5 px-0">
                            <div className="row">
                                {this.state.skeleton === true ?
                                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-7 col-12">
                                        <div className="Hotel_Details">
                                            <Card className="">
                                                <HotelDetails
                                                    errors={this.state.errors}
                                                    skeleton={this.state.skeleton}
                                                    searchTerms={this.state.searchTerms}
                                                    hotelDetails={this.state.hotelDetails}
                                                    selectedRoom={this.state.selectedRoom}
                                                />
                                            </Card>
                                        </div>
                                        <div className="Contact_Details">
                                            <Card className="">
                                                <ContactDetails skeleton={this.state.skeleton}/>
                                                <PassengerDetails skeleton={this.state.skeleton}/>
                                            </Card>
                                        </div>
                                        <div className="mt-4 GST_Details">
                                            <GSTDetails
                                                errors={this.state.errors}
                                                skeleton={this.state.skeleton}
                                                gstDetails={this.state.gstDetails}
                                            />
                                        </div>
                                    </div>
                                    :
                                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-7 col-12">
                                        <div className="Hotel_Details">
                                            <Card className="">
                                                <HotelDetails
                                                    errors={this.state.errors}
                                                    skeleton={this.state.skeleton}
                                                    searchTerms={this.state.searchTerms}
                                                    hotelDetails={this.state.hotelDetails}
                                                    selectedRoom={this.state.selectedRoom}
                                                />
                                            </Card>
                                        </div>

                                        <div className="Contact_Details mt-4">
                                            <Card className="">
                                                <ContactDetails
                                                    errors={this.state.errors}
                                                    contactDetails={this.state.contactDetails}
                                                    skeleton={this.state.skeleton}
                                                />

                                                <div className="col-12 mt-5">
                                                    <span className="room text-dark">Travellers Details</span>
                                                </div>
                                                {this.state.searchTerms.rooms.map((room, key) => {
                                                    return <PassengerDetails
                                                        errors={this.state.errors}
                                                        skeleton={this.state.skeleton}
                                                        room={room}
                                                        roomIndex={key}/>
                                                })}
                                            </Card>
                                        </div>
                                        <div className="mt-4 GST_Details">
                                            {this.state.gstDetails !== undefined ?
                                                <GSTDetails
                                                    errors={this.state.errors}
                                                    skeleton={this.state.skeleton}
                                                    gstDetails={this.state.gstDetails}
                                                />
                                                : ""
                                            }

                                        </div>
                                    </div>
                                }

                                <div
                                    className="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-12 pr-lg-0 Booking_Summery mt-s-4">
                                    <PaymentSummery
                                        skeleton={this.state.skeleton}
                                        loading={this.state.loading}
                                        searchTerms={this.state.searchTerms}
                                        selectedRoom={this.state.selectedRoom}
                                        bookRoom={this.bookRoom}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}